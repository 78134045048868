import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import { ComposedChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Line } from "recharts";
import styled from "styled-components";
import { fetchMeta } from "actions/dashboard";
import { n } from "@util";
import moment from "moment";
import { MESES } from "@util";

const DashboardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0 26px;
`;

const Chart = ({ isLoading, label, data = [], chartValue, width = 509, height = 195, percent, children, ...props }) => {
  const CustomizedLabel = ({ x, y, value, unit, width, height }) => {
    if (value === 0) {
      return (
        <text></text>
      )
    }

    const altura = y + height / 2;

    return (
      <text
        x={x + width + 5}
        y={altura + (5)}
        fontSize={'1vw'}
        fontWeight={600}
        fill="#222"
        textAnchor="start"
      >
        {unit ? unit + " " + n(value, 0) : n(value, 0)}
      </text>
    )
  };

  const CustomLegend = ({ payload }) => {
    const style = { color: '#666', fontSize: '2vw' };

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ margin: '0 10px', textAlign: 'center', display: 'flex' }}>
            <CustomSVG color={entry.color} />
            <span style={style}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  const CustomSVG = ({ color }) => {
    return <svg
      className="recharts-surface"
      width="14"
      height="14"
      viewBox="0 0 32 32"
      version="1.1"
      style={{ display: 'inline-flex', verticalAlign: 'middle', margin: 'auto 4px auto 4px', width: '2vw', height: '2vw' }}
    >
      <path
        stroke="none"
        fill={color}
        d="M0,4h32v24h-32z"
        className="recharts-legend-icon"
      />
    </svg>
  }

  return (
    <Spin spinning={isLoading}>
      {!!data && data.length > 0 ?
        <ComposedChart
          layout="vertical"
          width={window.innerWidth * 0.9}
          height={window.innerHeight * 0.9}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          barGap={0}
        >
          <CartesianGrid stroke="#f5f5f5" />
          {/* <XAxis type="number" domain={[0, 'dataMax + 20000']} /> */}
          <XAxis type="number" domain={[dataMin => (0), dataMax => (Math.floor(dataMax + 20000))]} />
          <YAxis dataKey="nome_operador" type="category" scale="band" width={100} />
          <Legend content={<CustomLegend />} />
          <Bar
            dataKey="carteira"
            fill="#1059ad"
            label={<CustomizedLabel />}
            minPointSize={5}
          />
          <Bar
            dataKey="faturado"
            fill="#02aed9"
            label={<CustomizedLabel />}
            minPointSize={5}
          />
          <Bar
            dataKey="vendido"
            fill="#34e5d5"
            label={<CustomizedLabel />}
            minPointSize={5}
          />
          <Line type="monotone" name="Meta" dataKey="meta" stroke="#ff7300" strokeWidth={3} strokeDasharray="5 5" dot={false} />
        </ComposedChart>
        : <div style={{
          width: 480,
          height: 280,
          top: 40,
          right: 55,
          left: 55,
          bottom: 20,
        }} />
      }
    </Spin>
  );
};
export default class MetaPanelOTIndividual extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      mes: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    (async () => {
      await this.fetchData();
      const interval = setInterval(this.fetchData, 300000);
      this.setState({ interval });
    })()
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    this.setState({ interval: false });
  }

  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      let mes = null;

      if (!!this.state.mes) {
        mes = this.state.mes.month() + 1;
      }

      const response = await fetchMeta(null, mes, true);
      if (response.success) {
        const { data } = response;
        this.setState({ data: data.meta });
      }
    } catch (err) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, data } = this.state;

    const currentMonth = MESES[moment().format('MM')];

    return (
      <div style={{ minHeight: 380 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: "center",
          fontSize: 19,
          height: 25
        }}>
          <h1 style={{ marginBottom: 0 }}>Televendas - Atingimento meta (R$) {currentMonth} - Por operador</h1>
        </div>
        <DashboardBody>
          <Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: 5, }}>
              <Chart isLoading={isLoading} title="Venda Mix" keyLabel="mes" unit="" titleX="Mês" data={data} />
            </div>
          </Fragment>
        </DashboardBody>
      </div>
    );
  }
}

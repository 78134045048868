import React, { Component } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Modal, Table, Pagination } from "antd";
import classnames from "classnames";
import _isEqual from "lodash/isEqual";

import { renderOrClone, compactPagination, compactFilters, compactSorter } from "./utils";
import TableDataSource from "./TableDataSource";
import WrappedValue from "./WrappedValue";
import Toolbar from "./Toolbar";

import "./FullTable.less";

export const mapColumn = (column, _, order = null) => {
  if ("children" in column && column.children.length > 0) {
    column = { ...column, children: column.children.map(mapColumn) };
  }

  if (!!order && !!column.sorter && "columnKey" in order && !!column.key && column.key === order.columnKey) {
    column = { ...column, sortOrder: order.order || "descend" };
  }

  return {
    dataIndex: column.key || null,
    dataType: "text",
    className: "text-center",
    width: "auto",
    render: (value, item) => {
      const type = column.dataType || column.renderType || "text";

      return (
        <WrappedValue
          value={
            type === "text" && !!column.dataParser && typeof column.dataParser === "function"
              ? column.dataParser(value, item)
              : value
          }
          type={type}
        />
      );
    },
    ...column,
  };
};

const showTotal = (total, range) => (total > 1 ? `${range[0]}-${range[1]} de ${total} itens` : `${total} item`);
const evenAndOdd = (_, index) => (index % 2 !== 0 ? `full-table--row full-table--row__odd` : `full-table--row`);

const beforeExit = event => {
  if (!!window && "sessionStorage" in window) {
    window.sessionStorage.setItem("exited", "1");
  }
};

@observer
export default class FullTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstRun: true,
    };
  }
  componentDidMount() {
    const { fetchOnMount = true, dataSource } = this.props;

    if (!!fetchOnMount && !!dataSource) this.fetchData();

    if (!!this.props.restorable) {
      window.addEventListener("beforeunload", beforeExit);
    }

    this.setState({ firstRun: false });
  }
  componentWillUnmount() {
    if (!!this.props.restorable) {
      window.removeEventListener("beforeunload", beforeExit);
    }
  }
  isLoading = loading => !!loading || !!this.props.dataSource.isLoading;
  updateDataSource = (pagination = null, filters = null, sorter = null) => {
    let diff = 0;

    const { dataSource, onChange, columns = [] } = this.props;
    const snapshot = dataSource.requestJSON;

    if (pagination !== null) {
      pagination = compactPagination(pagination);
      if (!_isEqual(pagination, snapshot.pagination)) {
        dataSource.pagination = pagination;
        diff++;
      }
    }
    if (filters !== null) {
      filters = compactFilters(filters);
      if (!_isEqual(filters, snapshot.filters)) {
        dataSource.filters = filters;
        diff++;
      }
    }
    if (sorter !== null) {
      sorter = compactSorter(sorter);
      if (!_isEqual(sorter, snapshot.sorter)) {
        dataSource.sorter = sorter;
        diff++;
      }
    }

    columns
      .filter(column => "filterParser" in column && typeof column.filterParser === "function")
      .forEach(column => {
        dataSource.filterParsers[column.key] = column.filterParser;
      });

    if (diff > 0) {
      if (!!onChange) {
        onChange(dataSource.requestJSON);
      } else {
        this.fetchData();
      }
    }
  };
  fetchData = async () => {
    try {
      if (!!this.state.firstRun && !!this.props.restorable && TableDataSource.restoreAction === "POP") {
        let forceRefreshItems = false;
        if (!!window && "sessionStorage" in window) {
          const isExited = window.sessionStorage.getItem("exited");
          if (!!isExited && isExited === "1") {
            forceRefreshItems = true;
            window.sessionStorage.setItem("exited", "0");
          }
        }

        const snapRestored = await this.props.dataSource.restoreSnapshot(forceRefreshItems);
        if (!!snapRestored && snapRestored !== false) {
          return snapRestored;
        }
      }

      const dsFetch = await this.props.dataSource.fetch(!!this.props.restorable);

      this.props.onFetch && this.props.onFetch();

      return dsFetch;
    } catch (err) {
      const { errorMessage = "Não foi possível obter os dados relacionados neste momento" } = this.props;

      Modal.error({
        title: "Erro na requisição",
        content: errorMessage,
      });

      this.props.onError && this.props.onError();
    }
  };
  columns = (injected = true) => {
    const { columns = [], dataSource } = this.props;
    const sorter = toJS(dataSource.sorter);

    if (!!injected) {
      return columns.map(column => {
        if (!!column.dataParser && typeof column.dataParser === "function") {
          return mapColumn(
            {
              ...column,
              dataParser: (value, item) =>
                column.dataParser(value, item, !!this.props.extraData ? this.props.extraData : null),
            },
            null,
            sorter
          );
        }

        return mapColumn(column, null, sorter);
      });
    }

    return columns.map((column, idx) => mapColumn(column, idx, sorter));
  };
  pagination = () => {
    const { dataSource, dataSourceMap = null, paginationMap = null } = this.props;
    if (dataSource.hasPagination) {
      if (!!paginationMap && typeof paginationMap === "function") {
        const items =
          !!dataSourceMap && typeof dataSourceMap === "function" ? dataSourceMap(dataSource.items) : dataSource.items;

        return paginationMap(toJS(dataSource.pagination), items);
      }

      return dataSource.pagination;
    }

    return null;
  };
  renderTitle = () => {
    const { actionBar = <Toolbar />, dataSource, extraData = null } = this.props;

    return (
      <div style={{ overflow: "auto", zoom: "1", cursor: "default" }}>
        {!!dataSource.hasPagination && (
          <Pagination
            style={{ float: "right" }}
            {...this.pagination()}
            simple
            size="small"
            onChange={(current, pageSize) => this.updateDataSource({ ...this.pagination(), current })}
          />
        )}
        {!!actionBar &&
          renderOrClone(actionBar, {
            dataSource,
            extraData,
            columns: this.columns(false),
            onUpdateDataSource: this.updateDataSource,
          })}
      </div>
    );
  };
  render() {
    let {
      TableComponent = null,
      columns,
      fetchOnMount,
      restorable,
      dataSource,
      dataSourceMap = null,
      paginationMap,
      paginationProps,
      className,
      wrapperClassName,
      locale = {},
      size = "middle",
      loading = false,
      bordered = true,
      rowClassName = evenAndOdd,
      actionBar,
      beforeComponent,
      afterComponent,
      extraData,
      children,
      ...tableProps
    } = this.props;

    if (!TableComponent) {
      TableComponent = Table;
    }

    const hasTitle =
      dataSource.fetched &&
      (actionBar !== "none" || (!actionBar && (!!dataSource.hasPagination || !!dataSource.hasFilter)));

    const FullTableComponent = (
      <TableComponent
        className={classnames(`full-table`, className)}
        title={hasTitle ? this.renderTitle : null}
        pagination={dataSource.hasPagination && { size: "small", showTotal, ...this.pagination(), ...paginationProps }}
        size={size}
        loading={this.isLoading(loading)}
        bordered={bordered}
        rowClassName={rowClassName}
        onChange={(pg, filters, order) => {
          if (dataSource.hasPagination && !!pg && +pg.current !== +dataSource.pagination.current) {
            this.updateDataSource(pg, null, null);
          } else {
            let freshFilters = !!filters ? { ...filters } : null;
            if (dataSource.hasFilter) {
              const currentFilters = toJS(dataSource.filters);
              freshFilters = !!freshFilters ? { ...currentFilters, ...freshFilters } : currentFilters;
            }

            this.updateDataSource(pg, freshFilters, order || null);
          }
        }}
        columns={this.columns().filter(c => !c.exportOnly)}
        dataSource={
          !!dataSourceMap && typeof dataSourceMap === "function" ? dataSourceMap(dataSource.items) : dataSource.items
        }
        locale={{
          filterTitle: "Filtrar",
          filterConfirm: "OK",
          filterReset: "Limpar",
          emptyText: "Sem dados encontrados para esta consulta ou filtro",
          ...locale,
        }}
        {...tableProps}
      />
    );

    return (
      <div className={classnames(`full-table-wrapper`, wrapperClassName)}>
        {!!beforeComponent && renderOrClone(beforeComponent, { dataSource, onUpdateDataSource: this.updateDataSource })}
        {dataSource.controlled && !dataSource.fetched && !this.isLoading(loading) && !!children
          ? children
          : FullTableComponent}
        {!!afterComponent && renderOrClone(afterComponent, { dataSource, onUpdateDataSource: this.updateDataSource })}
      </div>
    );
  }
}

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Carousel } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import MetaPanelOT from "components/dashboard/MetaPanelOT";
import MixPanelOT from "components/dashboard/MixPanelOT";
import MixPanelOTAut from "components/dashboard/MixPanelOTAut";
import MixPanelOTCar from "components/dashboard/MixPanelOTCar";
import MixPanelOTImob from "components/dashboard/MixPanelOTImob";
import SemaforoOTPanel from "components/dashboard/SemaforoOTPanel";
import Logoimage from "../../img/logoOkus.png";
import bgImage from "../../img/bg_dashboard.png";
import MetaPanelOTIndividual from "components/dashboard/MetaPanelOTIndividual";
import MixOTIndividual from "components/dashboard/MixOTIndividual";
import Ranking from "components/dashboard/ranking/Ranking";

const TelevendasAcessoScreen = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const carouselRef = useRef(null);

  const slideDurations = [15000, 15000, 15000, 30000, 30000, 15000, 15000, 15000, 15000, 15000, 15000];
  // const slideDurations = [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000];

  useEffect(() => {
    if (!isPaused) {
      const timeout = setTimeout(() => {
        const nextSlide = (currentSlide + 1) % slideDurations.length;
        setCurrentSlide(nextSlide);
        carouselRef.current.goTo(nextSlide);
      }, slideDurations[currentSlide]);

      return () => clearTimeout(timeout);
    }
    //eslint-disable-next-line
  }, [currentSlide, isPaused]);

  const renderDashboardMop = () => (
    <Fragment>
      <div style={{
        position: "absolute",
        display: "flex",
        zIndex: 0,
        pointerEvents: "none",
        opacity: "29%",
      }}>
        <img
          src={bgImage}
          alt="Okus"
          style={{ width: window.innerWidth, height: window.innerHeight, display: "block" }}
        />
      </div>
      <Button style={{ position: 'absolute', zIndex: 100, marginLeft: 10, marginTop: 10 }}
        onClick={() => setIsPaused(!isPaused)}>
        {isPaused ? 'Retomar' : 'Pausar'}
      </Button>
      <div style={{ zIndex: 0 }}>
        <Carousel ref={carouselRef} dots={true} beforeChange={(_, to) => setCurrentSlide(to)} infinite={false}>
          <SemaforoOTPanel tipo="nps" />
          <SemaforoOTPanel tipo="ticket_medio" />
          <SemaforoOTPanel tipo="venda_mix" />
          <MetaPanelOT tipo="Objetivo Faturamento" />
          <MetaPanelOTIndividual tipo="Objetivo Faturamento Individual" />
          <MixOTIndividual tipo="Venda Mix Individual" />
          <MixPanelOT />
          <MixPanelOTAut />
          <MixPanelOTCar />
          <MixPanelOTImob />
          <Ranking />
        </Carousel>
      </div>
      <div style={{
        position: "absolute",
        bottom: "12px",
        right: "12px",
        zIndex: 0,
        pointerEvents: "none",
        opacity: 5,
      }}>
        <img
          src={Logoimage}
          alt="Okus"
          style={{ width: "150px", height: "auto" }}
        />
      </div>
    </Fragment>
  );

  return (
    <div className="home-screen home-screen__vendedor" style={{ overflow: "hidden", height: "100vh" }}>
      {renderDashboardMop()}
    </div>
  );
};

export default withStore(observer(TelevendasAcessoScreen));

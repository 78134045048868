/**
 * @flow
 */

import { autorun, action, observable, computed, toJS } from "mobx";
import _size from "lodash/size";
import moment from "moment";
import memoize from "mem";

import { itemSeq, lastSeq, calcPMP, moneyFixed, n } from "@util";
import {
  fetchCliente,
  fetchAlvara,
  fetchCupom,
  fetchPedidoOriginal,
  fetchTransp,
  fetchStatus,
  fetchCondicao,
  fetchCashback,
  fetchRevogaCashback,
  fetchProduto,
  fetchKit,
} from "actions/pedido";
import { clearFetchCache, fetchApi, fetchPostApi } from "actions";

import Policy from "./Policy";
import Item, { PRECO_POSICIONADO, MIN_LITROS } from "./Item";
import { Modal } from "antd";

const TIPO_PRECO_LOJA = "TIPO_PRECO_LOJA";
const TIPO_PRECO_INDUSTRIA = "TIPO_PRECO_INDUSTRIA";
const TIPO_PRECO_EXPORTACAO = "TIPO_PRECO_EXPORTACAO";
const TIPO_PRECO_CONSTRUTORA = "TIPO_PRECO_CONSTRUTORA";
const TIPO_PRECO_OUTRO = "TIPO_PRECO_OUTRO";

const STORAGE_KEY = "@PortalANJO::";
const lsKey = k => STORAGE_KEY + `${k}`;

const reduceVolume = (acc, item) =>
  acc + (Math.max(+item.fator_conversao, MIN_LITROS) / (item.segum === "KG" ? item.densidade : 1)) * +item.quantidade;

export const isFilialFracionado = memoize(function isFilialFracionado(filial) {
  return filial === "03" || filial === "04" || filial === "05" || filial === "08";
});

export const isFobMatriz = function isFobMatriz(filial, permitefob, permiteCabotagem) {
  return filial === "01" && permitefob && permiteCabotagem;
};

function validateItemForPedido(pedido, item) {
  if (item.isValid) {
    if (pedido.isCampKit && !item.hasCampKit) {
      if (!pedido.hasCampKit) {
        throw new PedidoError(`Você precisa definir o kit antes de adicionar produtos a lista de venda!`);
      } else if (!pedido.canAddProdutoForaKit) {
        throw new PedidoError(`Este KIT não permite a adição de produtos fora da campanha.`);
      }
    }
    if (!item.isVendaCopoPermitida) {
      throw new PedidoError(`Este item deve ser lançado como Kit/caixa e não em quantidade avulsa de copos.`);
    }

    if (pedido.data.isValidKit && item.quantidade > item.quantidade_max_liberado_kit) {
      throw new PedidoError(`Quantidade informado acima da quantidade liberada pelo kit`);
    }

    const testExiste =
      pedido.hasItems &&
      !!pedido.items.find(
        citem =>
          //citem.neg_anterior === "N" &&
          citem.neg_anterior === item.neg_anterior &&
          citem.bonificado === item.bonificado &&
          citem.codigo_produto === item.codigo_produto &&
          citem.classe_produto === item.classe_produto &&
          citem.tipo_produto === item.tipo_produto
      );

    if (testExiste) {
      throw new PedidoError(
        `Produto já adicionado na lista de itens. Favor remover o item antes de tentar adicioná-lo novamente.`
      );
    }

    if (pedido.data.isValidKit && item.quantidade < item.quantidade_max_liberado_kit) {
      return true;
    }

    if (item.libera_pf_on === "NCM") {
      if ((pedido.policia_federal === "NAO" || item.libera_pf === "NAO")
        && item.policia_federal === "SIM"
      ) {
        throw new PedidoError(`Este cliente não possui liberação para comprar produto controlado pela Policia Federal!`);
      }
    } else if (item.libera_pf_on === "GLOBAL") {
      if (pedido.policia_federal === "NAO" && item.policia_federal === "SIM") {
        throw new PedidoError(`Este cliente não possui liberação para comprar produto controlado pela Policia Federal!`);
      }
    }


    if (!item.isVendaPermitida) {
      throw new PedidoError(`Este cliente não possui liberação para comprar este produto controlado!`);
    }

    if (!pedido.isB2B) {
      const testEX = item.ufcli === "EX";

      const testValidTipo =
        item.tipo_produto === "AMBOS" ||
        pedido.tipoItemPredominante === "AMBOS" ||
        pedido.tipoItemPredominante === item.tipo_produto;

      if (!testEX && !testValidTipo) {
        throw new PedidoError(`Não é possível misturar em um mesmo pedido itens de LINHA com itens ESPECIAIS!`);
      }
    }

    if (!!pedido.tipoSkuPredominante) {
      if (pedido.tipoSkuPredominante !== "BR" && item.tipo_sku === "BR") {
        throw new PedidoError(`Pedido não se trata de brinde, item não pode ser adicionado!`);
      }
      if (pedido.tipoSkuPredominante === "BR" && item.tipo_sku !== "BR") {
        throw new PedidoError(`Pedido de brinde, apenas itens de marketing podem ser adicionados!`);
      }
    }

    const s = `${pedido.data.status_pedido}`;

    if ((s === "4" || s === "5") && item.tipo_sku === "BR") {
      throw new PedidoError(
        `Não é possível adicionar itens do tipo brinde em pedidos com o status Amostra ou Bonificação.`
      );
    }

    if (s === "6" && item.tipo_sku !== "BR") {
      throw new PedidoError(`Apenas itens do tipo brinde são permitidos para pedidos com o status Brinde.`);
    }

    //validando a quantidade de itens total no pedido.
    const statusVld =
      pedido.data.status_pedido === "1" ||
      pedido.data.status_pedido === "2" ||
      pedido.data.status_pedido === "9" ||
      pedido.data.status_pedido === "A";
    const unidadeVld = pedido.data.unidade_negocio === "REVENDA";

    if (statusVld && unidadeVld && pedido.items.length >= pedido.data.max_qtd_itens_revenda) {
      throw new PedidoError(
        `Total de ${pedido.data.max_qtd_itens_revenda} itens para o mesmo pedido foi atingido. Não será permitida a inclusão do pedido, contate o comercial!`,
        103
      );
    }

    return true;
  }
  throw new PedidoError(`Produto inválido, tente configurar seu item novamente.`);
}

export class PedidoError extends Error {
  constructor(message, code = 0) {
    super(message);

    this.name = "PedidoError";
    this.code = code;
  }
}

interface IEntrega {
  codigo_entrega: null,
  endereco: null,
  numero: null,
  bairro: null,
  cidade: null,
  uf: null,
  cep: null,
}

export default class Pedido {
  today: String = null;
  @observable isLoading: boolean = false;

  @observable venda_interna: String = null;
  @observable codigo_operador: String = null;
  @observable codigo_vendedor: String = null;
  @observable permite_fob: boolean = false;
  @observable cabotagem: boolean = false;
  @observable numero_pedido: String = null;
  @observable pedido_original: String = null;
  @observable tipo_transp_disp: Array = ["T", "C"];
  @observable bonif_amostra_brinde: boolean = false;
  @observable pedido_complementar: boolean = false;
  @observable tipo_frete_enabled: boolean = true;
  @observable redesp_tipo_enabled: boolean = true;
  @observable valida_minimo: boolean = true;
  @observable descsimu_acima_politica: boolean = false;
  @observable prazosimu_acima_politica: boolean = false;
  @observable qtdsimu_rest_politica: number = 0;
  @observable codigo_condicao_enabled: boolean = true;
  @observable max_prazo_medio_solvente: number = 0;
  @observable max_prazo_medio_tintacomp: number = 0;
  @observable cliente_cod_ultima_cond_pgto: String = null;
  @observable cliente_dt_ultima_cond_pgto: String = null;
  @observable verba_codigo: String = null;
  @observable verba_ccusto: String = null;
  @observable verba_descc: String = null;
  @observable verba_valor: number = 0;
  @observable verba_utilizado: number = 0;
  @observable pedido_originalb2b: String = null;
  @observable pedido_originalb2b_peso: number = 0;
  @observable pedido_originalb2b_valido: Boolean = false;
  @observable desconto_volume_escalonado: Array = [];
  @observable policia_federal: String = null;
  @observable plataforma_vendedor: string = "NEXXUS";
  @observable codigo_solprazo: String = null;

  @observable.shallow policy: Policy = null;
  @observable proporcao: Array = [];

  @observable items: Array = [];
  @observable pendingItems: Array = [];
  @observable removedItems: Array = [];

  condicao_esp: Object = null;
  condicao_tc: Object = null;
  condicao_sol: Object = null;
  parcela_minima: number = 500;

  @observable
  data: Object = {
    unidade_negocio: "REVENDA",
    codigo_orcamento: null,
    codigo_cliente: null,
    loja_cliente: null,
    razao_social: null,
    limite_credito: 0,
    limite_credito_colig: 0,
    ultimo_faturamento: 0,
    ultima_compra: 0,
    ultimo_prazo: 0,
    codigo_alvara: null,
    prazo_medio_solvente: 0,
    prazo_medio_tintacomp: 0,
    programado: null,
    status_pedido: "2", // começa sempre em ORÇAMENTO...
    tipo_transp: null,
    tipo_frete: "C",
    codigo_transp: null,
    nome_transp: null,
    redesp_tipo: "C",
    redesp_transp: null,
    redesp_nome_transp: null,
    filial_faturamento: null,
    forma_pagto: "4",
    codigo_condicao: null,
    codigo_solicitacao: null,
    nome_condicao: null,
    qtd_parcelas: 1,
    prazo_medio: 1,
    cupom: null,
    cupom_valido: false,
    cupom_utilizado: 0,
    cupom_valor: 0,
    percentual_resgate_cashback: 0,
    saldo_resgate_cashback: 0,
    valor_resgate_cashback: 0,
    bonif_amostra_brinde: null,
    pedido_originalb2b: null,
    pedido_originalb2b_peso: 0,
    pedido_originalb2b_valido: false,
    paletizado: null,
    op_logistica: null,
    mod_logistica: null,
    obs_nota: null,
    ordem_compra: null,
    obs_carregto: null,
    obs_cliente: null,
    obs_simulacao: null,
    tipo_cliente: null,
    construtora: "N",
    uf: null,
    necessita_avaliacao: "N",
    motivo_avaliacao: null,
    motivo_simulacao: null,
    coligacao_simulacao: null,
    cliente_grande: null,
    cod_canal: null,
    peso_total_pedido: 30000,
    peso_minimo_revenda_01: 400,
    max_qtd_itens_revenda: 200,
    programacao_forames_revenda: "NAO",
    cashback_revenda: "NAO",
    nexxus_diretor: null,
    taxa_diaria_prazo: 0,
    prazomedio_referencia: 0,
    prazomedio_cliente: 0,
    calc_acresc_condpag: "NAO",
    calc_descre_condpag: "NAO",
    frmto_calc_condpag: "REFERENCIA",
    icms_operacao: 0,
    icms_interno: 0,
    verba_codigo: null,
    verba_ccusto: null,
    verba_descc: null,
    verba_valor: 0,
    verba_utilizado: 0,
    isAddSimula: false,
    desconto_volume_escalonado: null,
    cliente_entrega: null,
    loja_entrega: null,
    razao_social_entrega: null,
    venda_liberada: "SIM",
    policia_federal: "NAO",
    idioma_cliente: null,
    num_kit: null,
    kit_tintometrico: false,
    opcaoKit: "N",
    isClienteTintometrico: false,
    isShowOpcaoKit: false,
    isPromotor: false,
    orcamentoTintometrico: false,
    isValidKit: false
  };

  @observable
  entregas: IEntrega[] = []

  @observable
  selectedEntrega: Object = {
    codigo_entrega: null,
    endereco: null,
    numero: null,
    bairro: null,
    cidade: null,
    uf: null,
    cep: null,
  }

  @observable
  peso_minimo_parametro: number = 1500;

  constructor({
    codigo_operador = null,
    venda_interna = null,
    codigo_vendedor = null,
    permite_fob = null,
    unidade_negocio = "REVENDA",
  }) {
    this.today = moment().format("YYYY-MM-DD");
    if (!!codigo_operador) {
      this.codigo_operador = codigo_operador;
    } else if (!!venda_interna) {
      this.venda_interna = venda_interna;
    } else {
      this.updateVendedorData({ codigo_vendedor, permite_fob });
    }
    this.data.unidade_negocio = unidade_negocio;
    this.data.status_pedido = !!this.isB2B ? "1" : "2";
    this.parcela_minima = !!this.isB2B ? 700 : 500;

    this.registerListeners();
    // this.registerPersist();
    clearFetchCache();
  }

  registerListeners() {
    this._status = autorun(
      () => {
        Item.status_pedido = this.isSimulacao && !this.descsimu_acima_politica ? "2" : toJS(this.data.status_pedido);
        Item.unidade_negocio = toJS(this.data.unidade_negocio);
      },
      { delay: 180 }
    );
    this._policy = autorun(
      () => {
        if (!!this.policy) {
          const values = toJS(this.policy.values);
          this.pendingItems.map(pitem => pitem.applyPolicy(values));
          this.items.map(item => item.applyPolicy(values));
        }
      },
      { delay: 180 }
    );
    this._taxa = autorun(
      () => {
        if (!!this.hasCliente) {
          this.pendingItems.map(pitem => pitem.applyTaxa(this.condicaoTaxa));
          this.items.map(item => item.applyTaxa(this.condicaoTaxa));
        }
      },
      { delay: 180 }
    );
  }

  unregisterListeners() {
    if (!!this._status) this._status();
    if (!!this._policy) this._policy();
    if (!!this._taxa) this._taxa();
  }

  fetchItemAll = async (item) => {
    if (!!item.codigo_produto) {
      const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";
      const filial_faturamento = !!this.data.filial_faturamento
        ? `${this.data.filial_faturamento}`.padStart(2, "0")
        : "01";
      const tipo_frete = this.tipoFreteTabela;

      try {
        this.isLoading = true;
        const isNegociacaoAnterior = item.neg_anterior === 'S';

        if (isNegociacaoAnterior) {
          const tipo_transp = this.tipoFreteTabela;
          const status_pedido = this.data.status_pedido;
          const codigo = [codigo_cliente, loja_cliente, filial_faturamento].join("-");
          const path = "/pedidos/residuo/" + codigo + "/" + status_pedido + "/" + tipo_transp + "/" + this.data.unidade_negocio;

          const { data: response } = await fetchPostApi(path, { filter: [{ field: "query", value: item.codigo_produto }] });
          if (!!response.success) {
            return {
              data: response.data.items[0],
              quantidade: item.quantidade,
              desc_camp: item.desc_camp,
              campanha_desconto: item.campanha_desconto,
              desc_geren: item.desc_geren,
              desc_perfil: item.desc_perfil,
              preco_vendido: item.preco_vendido,
              neg_anterior: item.neg_anterior
            };
          } else {
            return null;
          }
        } else if (!!item.campKit && "codigo_kit" in item.campKit && !isNegociacaoAnterior) {
          const kitData = {
            codigo_kit: item.campKit.codigo_kit,
            codigo_cliente,
            op_logistica: this.data.op_logistica,
            cliente_retira: this.data.tipo_frete === "C" ? "NAO" : "SIM",
            tipo_transp: this.tipoFreteTabela,
            codprod: item.codigo_produto,
          }

          const response = await fetchKit(kitData);
          if (!!response.success) {
            return {
              data: response.data.items[0],
              quantidade: item.quantidade,
              desc_camp: item.desc_camp,
              campanha_desconto: item.campanha_desconto,
              desc_geren: item.desc_geren,
              desc_perfil: item.desc_perfil,
              preco_vendido: item.preco_vendido,
              neg_anterior: item.neg_anterior
            };
          } else {
            return null;
          }
        } else {
          const response = await fetchProduto({
            codigo_cliente,
            loja_cliente,
            filial_faturamento,
            tipo_frete,
            codigo_produto: item.codigo_produto,
            status: this.data.status_pedido,
            unidade_negocio: this.data.unidade_negocio,
            tipo_transp: this.data.tipo_transp,
            op_logistica: this.data.op_logistica,
            cliente_retira: this.data.tipo_frete === "C" ? "NAO" : "SIM",
          });
          if (!!response.success) {
            return {
              ...response,
              quantidade: item.quantidade,
              desc_camp: item.desc_camp,
              campanha_desconto: item.campanha_desconto,
              desc_geren: item.desc_geren,
              desc_perfil: item.desc_perfil,
              preco_vendido: item.preco_vendido,
              neg_anterior: item.neg_anterior
            };
          } else {
            return null;
          }
        }
      } catch (err) {
        return null;
      } finally {
        this.isLoading = false;
      }
    }
    return null;
  };


  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  persistVigente(rdata) {
    const today = new Date();
    const vigenciaMaisTres = this.addDays(rdata.vigencia, 3);
    const vigenteTresDias = today < vigenciaMaisTres;

    const mesAtual = today.getMonth();
    const mesVigencia = new Date(rdata.vigencia).getMonth();
    const vigentePorMes = mesAtual === mesVigencia;

    return vigenteTresDias && vigentePorMes;
  }

  registerPersist(isEdit) {
    const autoRunAsync = { delay: 200 };

    const restoredData = localStorage.getItem(lsKey(`persist:${isEdit || 'add'}`));

    if (!!restoredData) {
      const rdata = JSON.parse(restoredData);
      const vigente = this.persistVigente(rdata);

      if (vigente) {
        Modal.confirm({
          title: "Atenção!",
          content: "Parece que você tem um pedido pendente, deseja continuar de onde parou?",
          onOk: async () => {
            if ("data" in rdata) {
              this.data = rdata.data;
              if ("codigo_cliente" in rdata.data) await this.loadCliente();
            };

            if ("policy" in rdata) {
              this.policy = new Policy(rdata.policy.data, rdata.policy.anppol, rdata.policy.desconto_extra, rdata.policy.desconto_geren);
            }

            if ("items" in rdata) {
              let promises = [];

              const itemPromise = (iitem) => new Promise(resolve => {
                setTimeout(() => {
                  resolve(this.fetchItemAll(iitem));
                }, 100);
              });

              for (let i = 0; i < rdata.items.length; i++) {
                this.isLoading = true;
                promises.push(itemPromise({
                  codigo_produto: rdata.items[i]?.codigo_produto,
                  quantidade: rdata.items[i]?.quantidade,
                  desc_camp: rdata.items[i]?.desc_camp,
                  campanha_desconto: rdata.items[i]?.desc_camp,
                  desc_geren: rdata.items[i]?.desc_geren,
                  desc_perfil: rdata.items[i]?.desc_perfil,
                  preco_vendido: rdata.items[i]?.preco_vendido,
                  neg_anterior: rdata.items[i]?.neg_anterior,
                  campKit: rdata.items[i]?.campKit,
                }));
              }

              Promise.all(promises).then(response => {
                for (let j = 0; j < response.length; j++) {
                  const item = new Item(response[j].data, !!rdata.policy && rdata.policy.values);
                  item.quantidade = response[j].quantidade;
                  item.desc_camp = response[j].desc_camp;
                  item.campanha = { ...item.campanha, desconto: response[j].campanha_desconto };
                  item.desc_geren = response[j].desc_geren;
                  item.desc_perfil = response[j].desc_perfil;
                  item.preco_vendido = response[j].preco_vendido;
                  item.neg_anterior = response[j].neg_anterior;
                  try {
                    this.addItem(item);
                  } catch (err) {
                  }
                }
              });
            }

            for (const key of Object.keys(this)) {
              if (key in rdata && key !== "items" && key !== "codigo_cliente" && key !== "policy") {
                this[key] = rdata[key];
              }
            }
          },
          onCancel: () => {
            localStorage.removeItem(lsKey(`persist:${isEdit || 'add'}`));
          },
        });
      }
    }

    autorun(() => {
      const savedData = {};

      if (!!restoredData) {
        const rdata = JSON.parse(restoredData);
        if (!rdata.vigencia) {
          savedData['vigencia'] = new Date();
        } else {
          savedData['vigencia'] = rdata.vigencia;
        }
      } else {
        savedData['vigencia'] = new Date();
      }

      for (const key of Object.keys(this)) {
        if (key !== 'policy' && key !== 'pendingItems') {
          savedData[key] = this[key];
        } else if (key === 'policy') {
          savedData[key] = { ...this.policy, anppol: this.policy.anppol };
        }
      }
      localStorage.setItem(lsKey(`persist:${isEdit || 'add'}`), JSON.stringify(savedData));
    }, autoRunAsync);
  }

  clearPersist(isEdit = false) {
    localStorage.removeItem(lsKey(`persist:${isEdit || 'add'}`));
  }

  validateStep(currentStep = 1) {
    this.applyCashback();
    if (currentStep === 1) {

      if (this.hasTintaEspecial_Excecao && !this.isFilialProdEspecial) {
        throw new PedidoError(`Itens ESPECIAIS só podem ser vendidos pela Matriz (filial 01).`, 100);
      }
      if (_size(this.classes_litragem_minima) > 0 && this.isPedidoNormal && this.isFilialProdEspecial) {
        const classes = Object.keys(this.classes_litragem_minima);
        for (let j = 0, jc = classes.length; j < jc; j++) {
          let litragem_minima = Math.max(+this.classes_litragem_minima[classes[j]], MIN_LITROS);
          //eslint-disable-next-line
          this.items.filter(item => item.classe_produto === classes[j] && item.tipo_produto === 'ESPECIAL').map(item => {
            if (item.litragem < litragem_minima) {
              throw new PedidoError(
                `Itens ESPECIAIS da classe ${classes[j]} configurado(s) abaixo da litragem mínima estipulada (${litragem_minima}L).`,
                100
              );
            }
          });
        }
      } else {
        if (!this.isProporcaoValid) {
          throw new PedidoError(`Proporção não atingida`, 102);
        }

        if (this.peso_bruto_total > this.data.peso_total_pedido) {
          throw new PedidoError(
            `Pedido com peso superior a ${this.data.peso_total_pedido} kg. Não será possível incluir o pedido com esse peso, favor reavaliar!`,
            103
          );
        }

        if (!this.pesoMinimoValidado) {
          throw new PedidoError(
            `Não será permitido inserir pedido com peso inferior a ${this.peso_minimo_parametro} kg pela matriz, favor reavaliar!`,
            103
          );
        }

        /* CHECANDO NOVA CONDIÇÃO DE FOB PARA PEDIDOS ENTRE 1000 E 2500 */
        const pagtoStatus =
          this.data.status_pedido === "1" || this.data.status_pedido === "9" || this.data.status_pedido === "A";

        if (
          pagtoStatus &&
          !this.hasNegociacaoAnterior &&
          !this.hasTintaEspecial &&
          !this.hasBrinde &&
          this.data.filial_faturamento === "01" &&
          this.peso_bruto_total < this.data.peso_minimo_revenda_01 &&
          !this.isRegiaoSul
        ) {
          throw new PedidoError(
            `Pedido com peso inferior a ${this.data.peso_minimo_revenda_01} kg. Não será possível incluir o pedido com esse peso, favor reavaliar!`,
            103
          );
        }

        //validando a quantidade de itens total no pedido.
        const statusVld =
          this.data.status_pedido === "1" ||
          this.data.status_pedido === "2" ||
          this.data.status_pedido === "9" ||
          this.data.status_pedido === "A";
        const unidadeVld = this.data.unidade_negocio === "REVENDA";
        if (statusVld && unidadeVld && this.items.length > this.data.max_qtd_itens_revenda) {
          throw new PedidoError(
            `Total de ${this.data.max_qtd_itens_revenda} itens para o mesmo pedido foi atingido. Não será permitida a inclusão do pedido, contate o comercial!`,
            103
          );
        }

        const itemCount = this.items.length;
        if (itemCount > 0) {
          const codigosProdutos = this.items.map(item => item.codigo_produto + "/" + item.quantidade.toString());

          this.items.forEach(item => {
            if (
              item.bicomponente.length > 0 &&
              item.bicomponente.filter(
                bicomp => codigosProdutos.indexOf(bicomp.codigo + "/" + item.quantidade.toString()) !== -1
              ).length === 0
            ) {
              const qtd = item.quantidade.toString();
              const bicompNomes = item.bicomponente.map(
                bicomp => `<strong>${qtd} unidades de</strong>: ${bicomp.codigo} - ${bicomp.descricao} `
              );
              const apoio = bicompNomes.length > 1 ? `um dos produtos exigidos` : `um produto exigido`;

              throw new PedidoError(
                `Foi adicionado o produto <strong>${item.quantidade +
                " unidades de " +
                item.descricao}</strong> na sua lista de venda mas ${apoio} não foi adicionado: 

                - ${bicompNomes.join(" \nou \n- ")}`,
                101
              );
            }
          });
        }
      }
      this.codigo_condicao_enabled = true;
    }
    return true;
  }

  @action
  updateVendedorData({
    codigo_vendedor,
    permite_fob = "NAO",
    cabotagem = "NAO",
    valida_minimo = "SIM",
    descsimu_acima_politica = "NAO",
    prazosimu_acima_politica = "NAO",
    qtdsimu_rest_politica = 0,
    plataforma_vendedor = "NEXXUS",
  }) {
    this.codigo_vendedor = codigo_vendedor;
    this.permite_fob = `${permite_fob}`.toUpperCase() === "SIM";
    this.cabotagem = `${cabotagem}`.toUpperCase() === "SIM";
    this.valida_minimo = `${valida_minimo}`.toUpperCase() === "SIM";
    this.descsimu_acima_politica = `${descsimu_acima_politica}`.toUpperCase() === "SIM";
    this.prazosimu_acima_politica = `${prazosimu_acima_politica}`.toUpperCase() === "SIM";
    this.qtdsimu_rest_politica = Math.max(+qtdsimu_rest_politica, 0);
    this.plataforma_vendedor = plataforma_vendedor;
  }

  @computed
  get hasFilial() {
    return !!this.data.filial_faturamento;
  }

  @computed
  get pesoMinimoValidado() {
    if (
      !this.hasRastroResiduo &&
      this.isPedidoNormal &&
      this.data.filial_faturamento === "01" &&
      (this.data.uf === "GO" || this.data.uf === "DF" || this.data.uf === "TO") &&
      !this.hasBrinde
    ) {
      return this.peso_bruto_total >= this.peso_minimo_parametro;
    }
    else {
      return true;
    }
  }

  @computed
  get hasVendedor() {
    return !!this.codigo_vendedor;
  }

  @computed
  get hasCliente() {
    return this.hasFilial && !!this.data.codigo_cliente && !!this.data.loja_cliente;
  }

  @computed
  get hasPolicy() {
    return this.hasCliente && !!this.policy && "regiao_vendedor" in this.policy.data;
  }

  @computed
  get regiao_tabela() {
    if (!!this.policy && "regiao_vendedor" in this.policy.data) {
      return this.policy.data.regiao_vendedor;
    }
    return "";
  }

  @computed
  get isRegiaoSul() {
    return this.regiao_tabela === "006" || this.regiao_tabela === "021" || this.regiao_tabela === "025";
  }

  get isRegiaoSudeste() {
    return this.regiao_tabela === "004" || this.regiao_tabela === "010" || this.regiao_tabela === "011";
  }

  @computed
  get hasCampKit() {
    return this.hasCliente && this.isCampKit && this.items.length > 0 && !!this.items.find(item => !!item.hasCampKit);
  }

  @computed
  get hasCampSafra() {
    return this.hasCliente && this.isSafra && this.items.length > 0 && !!this.items.find(item => !!item.hasCampSafra);
  }

  @computed
  get hasProporcao() {
    return !!this.proporcao && this.proporcao.length > 0;
  }

  @computed
  get hasBonificado() {
    return this.hasCliente && this.items.length > 0 && !!this.items.find(item => item.bonificado === "S");
  }

  @computed
  get canAddProdutoForaKit() {
    return (
      this.hasCliente &&
      this.isCampKit &&
      this.items.length > 0 &&
      !!this.items.find(item => !!item.hasCampKit && item.campKit.produto_fora_kit.toUpperCase() === "SIM")
    );
  }

  @computed
  get hasTintaEspecial() {
    return this.tipoItemPredominante === "ESPECIAL";
  }

  @computed
  get hasTintaEspecial_Excecao() {
    return this.tipoItemPredominante_Excecao === "ESPECIAL";
  }

  @computed
  get hasItems() {
    return this.items.length > 0;
  }

  @computed
  get isDiretorInUse() {
    return this.data.nexxus_diretor === "SIM";
  }

  @computed
  get hasBrinde() {
    if (this.hasItems) {
      return !!this.items.find(item => !!item.isBrinde);
    }
    return false;
  }

  @computed
  get hasNegociacaoAnterior() {
    if (this.hasItems) {
      return !!this.items.find(item => !!item.isNegociacaoAnterior);
    }
    return false;
  }

  @computed
  get hasRastroResiduo() {
    if (this.hasItems) {
      return !!this.items.find(item => !!item.isRastroResiduo);
    }
    return false;
  }

  @computed
  get isB2B() {
    return !!this.data.unidade_negocio && this.data.unidade_negocio === "B2B";
  }

  @computed
  get isExportacao() {
    return !!this.data.tipo_cliente && this.data.tipo_cliente === 'X' && this.isB2B;
  }

  @computed
  get isEdit() {
    return this.hasCliente && !!this.numero_pedido;
  }

  @computed
  get isResiduo() {
    return (
      this.hasCliente &&
      !!this.numero_pedido &&
      !!this.pedido_original &&
      `${this.numero_pedido}` === `${this.pedido_original}`
    );
  }

  @computed
  get isBonificacao() {
    return this.hasFilial && this.data.status_pedido === "4";
  }

  get isComplementar() {
    return this.hasFilial && this.data.status_pedido === "C";
  }

  @computed
  get isAmostra() {
    return this.hasFilial && this.data.status_pedido === "5";
  }

  @computed
  get isBrinde() {
    return this.hasFilial && this.data.status_pedido === "6";
  }

  @computed
  get isSimulacao() {
    return this.hasFilial && this.data.status_pedido === "S";
  }

  @computed
  get isOrcamentoTintometrico() {
    return this.hasFilial && this.data.status_pedido === "T";
  }

  @computed
  get isPedidoNormal() {
    return (
      this.hasFilial &&
      (this.data.status_pedido === "1" ||
        this.data.status_pedido === "2" ||
        this.data.status_pedido === "9" ||
        this.data.status_pedido === "A")
    );
  }

  @computed
  get isFilialProdEspecial() {
    return (
      this.hasFilial &&
      (this.data.filial_faturamento === "01" ||
        this.data.filial_faturamento === "02" ||
        this.data.filial_faturamento === "06")
    );
  }

  @computed
  get isCampKit() {
    return this.hasFilial && this.data.status_pedido === "A";
  }

  @computed
  get isSafra() {
    return this.hasFilial && this.data.status_pedido === "9";
  }

  @computed
  get permiteCabotagem() {
    return this.hasFilial && this.cabotagem;
  }

  @computed
  get isCampKitOrSafra() {
    return this.isCampKit || this.isSafra;
  }

  @computed
  get isProporcaoValid() {
    if (this.hasItems) {
      if (this.hasProporcao) {
        const minimo = Math.max(+this.proporcao[0].proporcao_cabecalho, 0);
        return this.proporcaoAtingida >= +minimo;
      }
      return true;
    }
    return false;
  }

  @computed
  get proporcaoAtingida() {
    if (this.hasItems && this.hasProporcao) {
      const volumeTotal = Math.max(this.items.reduce(reduceVolume, 0), 0);
      const volumeProporcao = this.proporcao.reduce((volume, p) => {
        const proporcaoItems = this.items.filter(item => {
          if (!!p.linha_produto) {
            return item.codigo_linha === p.linha_produto && item.classe_produto.indexOf(p.classe_produto) === 0;
          }

          return item.classe_produto.indexOf(p.classe_produto) === 0;
        });

        return volume + Math.max(proporcaoItems.reduce(reduceVolume, 0), 0);
      }, 0);

      return Math.round((volumeProporcao / volumeTotal) * 100);
    }
    return 100;
  }

  @computed
  get isCashBack() {
    return this.isPedidoNormal && this.data.cod_canal !== "10";
  }

  @computed
  get prev_cashback() {
    if (this.isCashBack) {
      return moneyFixed(
        this.items.filter(item => item.cashback > 0).reduce((acc, item) => acc + item.vlrItemCashback, 0)
      );
    }
    return 0.0;
  }

  @computed
  get prev_cashback_Cimp() {
    if (this.isCashBack) {
      return moneyFixed(
        this.items.filter(item => item.cashback > 0).reduce((acc, item) => acc + item.vlrItemCashback, 0)
      );
    }
    return 0.0;
  }

  @computed
  get proporcaoClasses() {
    const proporcao = {
      "01": 0,
      "02": 0,
      "03": 0,
    };
    if (this.hasItems) {
      Object.keys(proporcao).forEach(classe => {
        proporcao[classe] = Math.max(
          this.items.filter(item => item.classe_produto.indexOf(classe) === 0).reduce(reduceVolume, 0),
          0
        );
      });
    }

    return proporcao;
  }

  @computed
  get tipoFreteTabela() {
    // regra especial - se for despacho FOB com transp 0008
    if (this.data.tipo_frete === "F" && this.data.codigo_transp === "0008" && this.data.unidade_negocio === "REVENDA") {
      return "C";
    }

    // se permitir FOB, testa se o vendedor definiu FOB como tipo de frete...
    if (
      !!this.permite_fob &&
      (this.data.tipo_frete === "F" || (this.data.tipo_frete === "C" && this.data.redesp_tipo === "F"))
    ) {
      return "F";
    }

    // caso não por padrão é sempre CIF...
    return "C";
  }

  @computed
  get tipoItemPredominante() {
    const itemCount = this.items.length;
    if (itemCount > 0) {
      for (let i = 0; i < itemCount; i++) {
        if (this.items[i].tipo_produto !== "AMBOS") {
          return this.items[i].tipo_produto;
        }
      }
    }
    return "AMBOS";
  }

  get tipoItemPredominante_Excecao() {
    const itemCount = this.items.length;
    if (itemCount > 0) {
      for (let i = 0; i < itemCount; i++) {
        if (this.items[i].codigo_produto !== '041000-23' && this.items[i].tipo_produto !== "AMBOS") {
          return this.items[i].tipo_produto;
        }
      }
    }
    return "AMBOS";
  }

  @computed
  get tipoSkuPredominante() {
    const itemCount = this.items.length;
    if (itemCount > 0) {
      return this.items[0].tipo_sku;
    }
    return null;
  }

  @computed
  get tipoPrecoMinimo() {
    if (this.hasCliente) {
      if (
        this.data.construtora !== "S" &&
        ["1", "9", "A"].indexOf(`${this.data.status_pedido}`) !== -1 &&
        (this.data.tipo_cliente === "S" || this.data.tipo_cliente === "F") &&
        this.tipoItemPredominante !== "ESPECIAL"
      ) {
        return TIPO_PRECO_LOJA;
      }
      if (["1", "9", "A"].indexOf(`${this.data.status_pedido}`) !== -1 && this.data.tipo_cliente === "R") {
        return TIPO_PRECO_INDUSTRIA;
      }
      if (["1", "9", "A"].indexOf(`${this.data.status_pedido}`) !== -1 && this.data.tipo_cliente === "X") {
        return TIPO_PRECO_EXPORTACAO;
      }
      if (["1", "9", "A"].indexOf(`${this.data.status_pedido}`) !== -1 && this.data.construtora === "S") {
        return TIPO_PRECO_CONSTRUTORA;
      }
    }
    return TIPO_PRECO_OUTRO;
  }

  @computed
  get precoMinimo() {
    if (this.hasCliente) {
      if (this.hasBrinde || this.data.cod_canal === "10") {
        return 1.0;
      }
      //ajustando pedido minimo da tech
      if (this.data.filial_faturamento === "02") {
        return 1000.0;
      }
      //ajustando pedido minimo da print
      if (this.data.filial_faturamento === "06" && this.tipoPrecoMinimo === TIPO_PRECO_EXPORTACAO) {
        return 500.0;
      }
      if (this.data.filial_faturamento === "06" && this.tipoPrecoMinimo !== TIPO_PRECO_EXPORTACAO) {
        return 1000.0;
      }
      //FINALIZACAO AJUSTE PRINT...
      if (
        this.data.tipo_frete === "F" &&
        (this.data.filial_faturamento === "03" || this.data.filial_faturamento === "01" || this.data.filial_faturamento === "08")
      ) {
        return 1500.0;
      }
      if (
        this.data.tipo_frete === "F" &&
        (this.data.filial_faturamento === "04" || this.data.filial_faturamento === "05")
      ) {
        return 1500.0;
      }

      if (this.tipoPrecoMinimo === TIPO_PRECO_LOJA) {
        return 3000.0;
      }
      if (this.tipoPrecoMinimo === TIPO_PRECO_INDUSTRIA) {
        return 1000.0;
      }
      if (this.tipoPrecoMinimo === TIPO_PRECO_CONSTRUTORA) {
        return 1500.0;
      }
    }
    return 0.0;
  }

  @computed
  get condicaoTaxa() {
    if (this.data.unidade_negocio === "REVENDA" && !!this.hasCliente && !!this.data.codigo_condicao) {
      let prazo_medio = Math.round(+this.data.prazo_medio);
      if (prazo_medio === 1) {
        prazo_medio = 0;
      }

      let diasTaxa = 0;
      if (this.data.frmto_calc_condpag === "REFERENCIA") {
        diasTaxa = prazo_medio - this.data.prazomedio_referencia;
      } else {
        diasTaxa = prazo_medio - this.data.prazomedio_cliente;
      }

      if (
        (diasTaxa > 0 && this.data.calc_acresc_condpag === "SIM") ||
        (diasTaxa < 0 && this.data.calc_descre_condpag === "SIM")
      ) {
        return moneyFixed(diasTaxa * this.data.taxa_diaria_prazo * -1);
      }
    }
    return 0;
  }

  @computed
  get hasDescVolumeEscalonadoSolvente() {
    return (
      !!this.data.desconto_volume_escalonado &&
      this.data.desconto_volume_escalonado.filter(desc => desc.classe === "01").length > 0
    );
  }

  @computed
  get hasDescVolumeEscalonadoComplemento() {
    return (
      !!this.data.desconto_volume_escalonado &&
      this.data.desconto_volume_escalonado.filter(desc => desc.classe === "02").length > 0
    );
  }

  @computed
  get hasDescVolumeEscalonadoTinta() {
    return (
      !!this.data.desconto_volume_escalonado &&
      this.data.desconto_volume_escalonado.filter(desc => desc.classe === "03").length > 0
    );
  }

  @computed
  get descVolumeSolvente() {
    if (this.hasDescVolumeEscalonadoSolvente) {
      const desc = this.data.desconto_volume_escalonado.filter(
        desc =>
          desc.classe === "01" && this.volume_solvente >= desc.faixa_inicial && this.volume_solvente <= desc.faixa_final
      );
      if (this.hasItems && desc.length > 0) {
        return desc[0].desconto;
      }
    }
    return 0;
  }

  @computed
  get descVolumeComplemento() {
    if (this.hasDescVolumeEscalonadoComplemento) {
      const desc = this.data.desconto_volume_escalonado.filter(
        desc =>
          desc.classe === "02" &&
          this.volume_complemento >= desc.faixa_inicial &&
          this.volume_complemento <= desc.faixa_final
      );
      if (this.hasItems && desc.length > 0) {
        return desc[0].desconto;
      }
    }
    return 0;
  }

  @computed
  get descVolumeTinta() {
    if (this.hasDescVolumeEscalonadoTinta) {
      const desc = this.data.desconto_volume_escalonado.filter(
        desc => desc.classe === "03" && this.volume_tinta >= desc.faixa_inicial && this.volume_tinta <= desc.faixa_final
      );
      if (this.hasItems && desc.length > 0) {
        return desc[0].desconto;
      }
    }
    return 0;
  }

  @computed
  get detalhe_descvol() {
    return this.data.desconto_volume_escalonado;
  }

  /* TOTAIS */
  @computed
  get total_auto() {
    if (this.hasItems) {
      return moneyFixed(
        this.items.filter(item => item.isLinhaAuto).reduce((acc, item) => acc + item.valor_total_bruto, 0)
      );
    }
    return 0.0;
  }

  @computed
  get desconto_auto_total() {
    if (this.hasItems) {
      const valor_total = this.items
        .filter(item => item.isLinhaAuto && item.tipoDescontoCampanha !== PRECO_POSICIONADO)
        .reduce((acc, item) => acc + item.valor_total, 0);
      return moneyFixed(Math.max(this.total_auto - valor_total, 0));
    }
    return 0.0;
  }

  @computed
  get total_imob() {
    if (this.hasItems) {
      return moneyFixed(
        this.items.filter(item => item.isLinhaImob).reduce((acc, item) => acc + item.valor_total_bruto, 0)
      );
    }
    return 0.0;
  }

  @computed
  get desconto_imob_total() {
    if (this.hasItems) {
      const valor_total = this.items
        .filter(item => item.isLinhaImob && item.tipoDescontoCampanha !== PRECO_POSICIONADO)
        .reduce((acc, item) => acc + item.valor_total, 0);
      return moneyFixed(Math.max(this.total_imob - valor_total, 0));
    }
    return 0.0;
  }

  @computed
  get peso_bruto_total() {
    if (this.hasItems) {
      return moneyFixed(this.items.reduce((acc, item) => acc + item.peso_bruto_total, 0));
    }
    return 0.0;
  }

  get volume_tinta() {
    if (this.hasItems) {
      if (this.isB2B) {
        return moneyFixed(
          this.items
            .filter(item => item.classe_produto.substr(0, 2) === "03")
            .reduce((acc, item) => acc + item.quantidade, 0)
        );
      } else {
        return moneyFixed(
          this.items
            .filter(item => item.classe_produto.substr(0, 2) === "03")
            .reduce((acc, item) => acc + item.quantidade * item.fator_conversao, 0)
        );
      }
    }
    return 0.0;
  }

  get volume_complemento() {
    if (this.hasItems) {
      if (this.isB2B) {
        return moneyFixed(
          this.items
            .filter(item => item.classe_produto.substr(0, 2) === "02")
            .reduce((acc, item) => acc + item.quantidade, 0)
        );
      } else {
        return moneyFixed(
          this.items
            .filter(item => item.classe_produto.substr(0, 2) === "02")
            .reduce((acc, item) => acc + item.quantidade * item.fator_conversao, 0)
        );
      }
    }
    return 0.0;
  }

  get volume_solvente() {
    if (this.hasItems) {
      if (this.isB2B) {
        return moneyFixed(
          this.items
            .filter(item => item.classe_produto.substr(0, 2) === "01")
            .reduce((acc, item) => acc + item.quantidade, 0)
        );
      } else {
        return moneyFixed(
          this.items
            .filter(item => item.classe_produto.substr(0, 2) === "01")
            .reduce((acc, item) => acc + item.quantidade * item.fator_conversao, 0)
        );
      }
    }
    return 0.0;
  }

  @computed
  get desconto_total() {
    if (this.hasItems) {
      const valor_total_bruto = this.items.reduce((acc, item) => acc + item.valor_total_bruto, 0);
      const valor_total = this.items.reduce((acc, item) => acc + item.valor_total, 0);
      const desconto_total = 1 - valor_total / valor_total_bruto;
      return moneyFixed(desconto_total * 100);
    }
    return 0.0;
  }

  @computed
  get valor_total_sem_taxa() {
    if (this.hasItems) {
      const valor_total_sem_taxa = this.items.reduce((acc, item) => acc + item.valor_total_sem_taxa, 0);
      return moneyFixed(valor_total_sem_taxa);
    }
    return 0.0;
  }

  @computed
  get valor_total() {
    if (this.hasItems) {
      const valor_total = this.items.reduce((acc, item) => acc + item.valor_total, 0);
      return moneyFixed(valor_total);
    }
    return 0.0;
  }

  @computed
  get valor_parcela() {
    if (this.hasItems && +this.valor_total > 0) {
      return moneyFixed(+this.valor_total / +this.data.qtd_parcelas);
    }
    return 0.0;
  }

  @computed
  get valor_ipi() {
    if (this.hasItems) {
      const valor_ipi = this.items.reduce((acc, item) => acc + item.valor_ipi, 0);
      return moneyFixed(valor_ipi);
    }
    return 0.0;
  }

  @computed
  get base_icms() {
    if (this.hasItems) {
      const base_icms = this.items.reduce((acc, item) => acc + item.base_icms, 0);
      return moneyFixed(base_icms);
    }
    return 0.0;
  }

  @computed
  get valor_icms() {
    if (this.hasItems) {
      const valor_icms = this.items.reduce((acc, item) => acc + item.valor_icms, 0);
      return moneyFixed(valor_icms);
    }
    return 0.0;
  }

  @computed
  get base_st() {
    if (this.hasItems) {
      const base_st = this.items.reduce((acc, item) => acc + item.base_st, 0);
      return moneyFixed(base_st);
    }
    return 0.0;
  }

  @computed
  get valor_icms_st() {
    if (this.hasItems) {
      const valor_icms_st = this.items.reduce((acc, item) => acc + item.valor_icms_st, 0);
      return moneyFixed(valor_icms_st);
    }
    return 0.0;
  }

  @computed
  get valor_suframa() {
    if (this.hasItems) {
      const valor_desc_suframa = this.items.reduce((acc, item) => acc + item.valor_suframa, 0);
      return moneyFixed(valor_desc_suframa);
    }
    return 0.0;
  }

  @computed
  get valor_total_nf() {
    if (this.hasItems) {
      const valor_total_nf = this.items.reduce((acc, item) => acc + item.valor_total_nf, 0);
      return moneyFixed(valor_total_nf);
    }
    return 0.0;
  }

  @computed
  get total_bonificado() {
    if (this.hasItems) {
      return moneyFixed(
        this.items
          .filter(item => item.bonificado === "S" && item.neg_anterior !== "S")
          .reduce((acc, item) => acc + item.valor_total_nf, 0)
      );
    }
    return 0.0;
  }

  @computed
  get validou_bonificado() {
    let validou = false;
    if (this.hasItems) {
      const total_bonificado = this.total_bonificado;
      //const verba_aux = this.data.verba_utilizado > 0 ? this.data.verba_utilizado - total_bonificado : this.data.verba_utilizado;
      const verba_utilizado = this.data.verba_utilizado; //verba_aux < 0 ? 0 : verba_aux;
      const saldo_bonificado = this.data.verba_valor - (verba_utilizado + total_bonificado);

      validou = saldo_bonificado > 0;
    }
    return validou;
  }

  @computed
  get pmp() {
    if (this.hasItems) {
      const total_solv = this.items.filter(item => item.isSolvente).reduce((acc, item) => acc + item.valor_total, 0);
      const total_tc = this.items.filter(item => item.isTintaComp).reduce((acc, item) => acc + item.valor_total, 0);

      let total = 0;
      if (total_solv > 0) {
        total += +this.data.prazo_medio_solvente * total_solv;
      }
      if (total_tc > 0) {
        total += +this.data.prazo_medio_tintacomp * total_tc;
      }

      if (total > 0) {
        const p = Math.round(total / (total_solv + total_tc));
        return this.isB2B ? p : calcPMP(p);
      }
    }
    return 1;
  }

  @computed
  get classes_litragem_minima() {
    return this.items
      .filter(item => item.tipo_produto === "ESPECIAL" && +item.venda_litragem_minima > 0)
      .reduce((acc, item) => ({ ...acc, [item.classe_produto]: +item.venda_litragem_minima }), {});
  }
  /* END */

  lastItemProduto() {
    if (this.items.length > 0 || this.removedItems.length > 0) {
      return lastSeq(
        [].concat(
          this.items.map(k => toJS(k.item_produto || "00")),
          this.removedItems.map(k => toJS(k.item_produto || "00"))
        )
      );
    }
    return "00";
  }

  // @action
  // addItem(item: Item) {
  //   this.isLoading = true;
  //   return new Promise((resolve, reject) => {
  //     if (validateItemForPedido(this, item)) {
  //       if (this.data.num_kit && this.data.kit_tintometrico) {
  //         fetchApi(`/pedidos/get-product-ticket/${item.codigo_produto}`).then(response => {
  //           const quantidadeLiberada = response.data.data.zy1_qntd * item.quantidade_por_fardo;

  //           if (item.quantidade > quantidadeLiberada) {
  //             message.error(`Quantidade informada inválida! Quantidade liberada: ${quantidadeLiberada}`);
  //             this.isLoading = false;
  //             reject();
  //           } else {
  //             item.item_produto = itemSeq(this.lastItemProduto());
  //             this.items.push(item);
  //             this.resetCondicao();
  //             this.applyCashback();
  //             this.isLoading = false;
  //             resolve(true);
  //           }
  //         });
  //       } else {
  //         item.item_produto = itemSeq(this.lastItemProduto());
  //         this.items.push(item);
  //         this.resetCondicao();
  //         this.applyCashback();
  //         this.isLoading = false;
  //         resolve(true);
  //       }
  //     }
  //   });
  // }

  @action
  addItem(item: Item) {
    if (validateItemForPedido(this, item)) {
      item.item_produto = itemSeq(this.lastItemProduto());
      this.items.push(item);
      this.resetCondicao();
      this.applyCashback();
      return true;
    }
    return false;
  }

  @action
  fetchDescontoVolume() {
    for (let i = 0; i !== this.items.length; ++i) {
      if (
        !!this.descVolumeTinta &&
        this.descVolumeTinta > 0 &&
        this.hasDescVolumeEscalonadoTinta &&
        this.items[i].classe_produto.substr(0, 2) === "03"
      ) {
        this.items[i].desconto_volume = this.descVolumeTinta;
      } else if (
        !!this.descVolumeComplemento &&
        this.descVolumeComplemento > 0 &&
        this.hasDescVolumeEscalonadoComplemento &&
        this.items[i].classe_produto.substr(0, 2) === "02"
      ) {
        this.items[i].desconto_volume = this.descVolumeComplemento;
      } else if (
        !!this.descVolumeSolvente &&
        this.descVolumeSolvente > 0 &&
        this.hasDescVolumeEscalonadoSolvente &&
        this.items[i].classe_produto.substr(0, 2) === "01"
      ) {
        this.items[i].desconto_volume = this.descVolumeSolvente;
      } else {
        this.items[i].desconto_volume = 0;
      }
      this.items[i].calculaPreco();
    }
  }

  @action
  applyCashback() {
    let totalClasse = 0;
    let somaClasse = "";
    let classe = "";
    let cashback = 0;
    let dias_cashback = 0;
    let somaclasse_cashback = "";
    let config = [];
    let i = 0;
    let j = 0;

    if (this.data.cashback_revenda === "SIM" && this.plataforma_vendedor === "NEXXUS PRO") {
      for (i = 0; i !== this.items.length; ++i) {
        this.items[i].cashback = 0;
        this.items[i].dias_cashback = 0;
        this.items[i].valor_cashback = 0;
        this.items[i].somaclasse_cashback = "";

        if (this.isCashBack) {
          config = this.items[i].config_cashback;
          if (config.length > 0) {
            classe = this.items[i].classe_produto;
            somaClasse = config[0].soma_classe;
            totalClasse = 0;
            for (j = 0; j !== this.items.length; ++j) {
              if (this.items[j].classe_produto === classe) {
                if (somaClasse === "LITROS") {
                  totalClasse += this.items[j].litragem;
                } else {
                  totalClasse += this.items[j].__quantidade;
                }
              }
            }
            for (j = 0; j !== config.length; ++j) {
              if (config[j].qtd_inicial <= totalClasse && config[j].qtd_final >= totalClasse) {
                cashback = config[j].perc_cashback;
                dias_cashback = config[j].dias_cashback;
                somaclasse_cashback = config[j].soma_classe;
              }
            }
            //atualizando todos os produtos com o cashback
            if (cashback > 0) {
              for (j = 0; j !== this.items.length; ++j) {
                if (this.items[j].classe_produto === classe) {
                  this.items[j].cashback = cashback;
                  this.items[j].dias_cashback = dias_cashback;
                  this.items[j].somaclasse_cashback = somaclasse_cashback;
                  this.items[j].valor_cashback = this.items[j].vlrItemCashback;
                }
              }
            }
          }
        }
      }
    }
  }

  @action
  updateItem(item: Item) {
    if (this.hasItems && !!item.codigo_produto && item.isValid) {
      let valid = true;
      const idx = this.items.findIndex(
        citem =>
          citem.item_produto === item.item_produto &&
          citem.codigo_produto === item.codigo_produto &&
          citem.classe_produto === item.classe_produto &&
          citem.neg_anterior === item.neg_anterior &&
          citem.bonificado === item.bonificado
      );

      if (idx >= 0) {
        const idxBonificado = this.items.findIndex(
          citem =>
            citem.codigo_produto === item.codigo_produto &&
            citem.classe_produto === item.classe_produto &&
            citem.neg_anterior === "N" &&
            citem.bonificado === "S" &&
            item.bonificado !== "S"
        );
        if (idxBonificado > 0) {
          if (this.items[idxBonificado].quantidade > item.quantidade) {
            valid = false;
          }
        }
        if (valid) {
          this.items[idx] = item;
          this.resetCondicao();
          this.fetchDescontoVolume();
          this.applyCashback();
        } else {
          throw new PedidoError(
            `Existe item bonificado no seu pedido e a quantidade dele não pode ser maior que a quantidade deste item!`
          );
        }
      } else {
        this.addItem(item);
      }
    }
  }

  @action
  removeItem(item: Item) {
    if (this.hasItems && !!item.codigo_produto) {
      const idx = this.items.findIndex(
        citem =>
          citem.item_produto === item.item_produto &&
          citem.codigo_produto === item.codigo_produto &&
          citem.classe_produto === item.classe_produto &&
          citem.neg_anterior === item.neg_anterior &&
          citem.bonificado === item.bonificado
      );

      if (idx >= 0) {
        /* SE ESTIVER REMOVENDO UM ITEM JÁ EXISTENTE NO BANCO EM UMA EDIÇÃO DE PEDIDO, REGISTRAR PARA POSTERIOR UPDATE EXCLUSIVO */
        if (this.isEdit && item.mode === "edit") {
          item.mode = "delete";
          this.removedItems.push(item);
        }
        //deletando o item principal
        this.items.splice(idx, 1);
        this.resetCondicao();
        this.applyCashback();
      }

      const idxBonificado = this.items.findIndex(
        citem =>
          citem.codigo_produto === item.codigo_produto &&
          citem.classe_produto === item.classe_produto &&
          citem.neg_anterior === item.neg_anterior &&
          citem.bonificado === "S" &&
          item.bonificado !== "S"
      );

      if (idxBonificado >= 0) {
        if (this.isEdit && item.mode === "edit") {
          this.items[idxBonificado].mode = "delete";
          this.removedItems.push(this.items[idxBonificado]);
        }
        this.items.splice(idxBonificado, 1);
      }
    }
  }

  @action
  adicionarNumeroTicket(num_ticket) {
    this.data.num_kit = num_ticket;
    this.data.kit_tintometrico = true;
  }

  @action
  removerNumeroTicket() {
    this.data.num_kit = null;
    this.data.kit_tintometrico = false;
  }

  @action
  opcaoKit(opcao) {
    if (String(opcao) === 'S') {
      this.data.opcaoKit = opcao;
      return;
    }

    this.data.opcaoKit = opcao;
    this.data.obs_carregto = " ";
    this.data.obs_nota = " ";
    this.removerNumeroTicket()
  }

  @action
  handleChangeOpcaoKit(value) {
    this.data.isShowOpcaoKit = value;
  }

  @action
  removeCampKit(codigo_kit) {
    if (this.hasItems && !!codigo_kit) {
      const campKitItems = this.items.filter(item => item.hasCampKit && item.campKit.codigo_kit === codigo_kit);
      if (campKitItems.length > 0) {
        const totalCampKitItems = campKitItems.length;
        for (let j = 0; j < totalCampKitItems; j++) {
          this.removeItem(campKitItems[j]);
        }
      }
    }
  }

  @action
  async selectEntrega({ codigo_entrega, ...entrega }, index) {
    this.selectedEntrega = { codigo_entrega, index, ...entrega };
  }

  @action
  async loadCliente(forceReset = true) {
    if (!!this.data.codigo_cliente) {
      const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";
      const filial_faturamento = !!this.data.filial_faturamento
        ? `${this.data.filial_faturamento}`.padStart(2, "0")
        : "01";

      this.isLoading = true;

      try {
        const response = await fetchCliente({ codigo_cliente, loja_cliente, filial_faturamento });
        if (!!response.success) {
          const { cliente, policy, vendedor = {}, mod_logistica } = response.data;

          this.data.codigo_cliente = codigo_cliente;
          this.data.unidade_negocio = cliente.Cliente.unidade_negocio || "REVENDA";
          this.data.loja_cliente = cliente.Cliente.loja;
          this.data.razao_social = cliente.Cliente.razao_social;
          this.data.limite_credito = cliente.Cliente.limite_credito;
          this.data.limite_credito_colig = cliente.Cliente.limite_credito_colig;
          this.data.ultimo_faturamento = cliente.Cliente.ultimo_faturamento;
          this.data.ultima_compra = cliente.Cliente.ultima_compra;
          this.data.ultimo_prazo = cliente.Cliente.ultimo_prazo;
          this.data.tipo_cliente = cliente.Cliente.tipo_cliente || "X";
          this.data.construtora = cliente.Cliente.construtora || "N";
          this.data.uf = cliente.Cliente.uf;
          this.data.mod_logistica = mod_logistica;
          this.data.cliente_grande = cliente.Cliente.cliente_grande;
          this.data.cod_canal = cliente.Cliente.cod_canal;
          this.data.icms_operacao = +cliente.ICMS.icms_operacao;
          this.data.icms_interno = +cliente.ICMS.icms_interno;
          this.data.prazo_medio_solvente = +cliente.Cliente.prazo_medio_solvente;
          this.data.prazo_medio_tintacomp = +cliente.Cliente.prazo_medio_tintacomp;
          this.data.prazomedio_cliente = Math.max(+cliente.Cliente.prazomedio_cliente, 0);
          this.data.calc_acresc_condpag = cliente.Cliente.calc_acresc_condpag || "NAO";
          this.data.calc_descre_condpag = cliente.Cliente.calc_descre_condpag || "NAO";
          this.data.frmto_calc_condpag = cliente.Cliente.frmto_calc_condpag || "REFERENCIA";
          this.data.saldo_total = cliente.boleto.saldo_total;
          this.data.saldo_venc = cliente.boleto.saldo_venc;
          this.data.data_atu_cad = cliente.Cliente.data_atu_cad;
          this.max_prazo_medio_solvente = +cliente.Cliente.prazo_medio_solvente;
          this.max_prazo_medio_tintacomp = +cliente.Cliente.prazo_medio_tintacomp;
          this.parcela_minima = this.data.cod_canal === "10" ? 50 : !!this.isB2B ? 700 : 500;
          this.data.desconto_volume_escalonado = "DescVolume" in cliente ? cliente.DescVolume : null;
          this.policia_federal = cliente.Cliente.policia_federal || "NAO";
          this.isAtualizado = cliente.Cliente.data_atu_cad ? "SIM" : "NAO";
          this.isAtualizaOn = cliente.Cliente.atualiza_on;
          this.statusCad = cliente.Cliente.status_cad;
          this.contribuinte = cliente.Cliente.contribuinte;
          this.entregas = cliente.Entregas;
          this.peso_minimo_parametro = parseFloat(cliente.parametro.peso_minimo.replace(/,/g, '.'));

          if (!!cliente.Cliente.cd_ultima_cond_pgto)
            this.cliente_cod_ultima_cond_pgto = cliente.Cliente.cd_ultima_cond_pgto;
          if (!!cliente.Cliente.dt_ultima_cond_pgto)
            this.cliente_dt_ultima_cond_pgto = cliente.Cliente.dt_ultima_cond_pgto.split(" ")[0];

          this.condicao_esp =
            "CondicaoEsp" in cliente && !!cliente.CondicaoEsp && !!cliente.CondicaoEsp.codigo_condicao
              ? { ...cliente.CondicaoEsp }
              : null;
          this.condicao_tc =
            "CondicaoTc" in cliente && !!cliente.CondicaoTc && !!cliente.CondicaoTc.codigo_condicao
              ? { ...cliente.CondicaoTc }
              : null;
          this.condicao_sol =
            "CondicaoSol" in cliente && !!cliente.CondicaoSol && !!cliente.CondicaoSol.codigo_condicao
              ? { ...cliente.CondicaoSol }
              : null;

          if (!!cliente.VerbaBon) {
            this.data.verba_codigo = cliente.VerbaBon.codigo;
            this.data.verba_ccusto = cliente.VerbaBon.centro_custo;
            this.data.verba_descc = cliente.VerbaBon.desc_custo;
            this.data.verba_valor = +cliente.VerbaBon.verba_valor;
            this.data.verba_utilizado = +cliente.VerbaBon.verba_utilizado;
          } else {
            this.data.verba_codigo = null;
            this.data.verba_ccusto = null;
            this.data.verba_descc = null;
            this.data.verba_valor = 0;
            this.data.verba_utilizado = 0;
          }

          if (!!vendedor.codigo) {
            this.updateVendedorData({
              codigo_vendedor: vendedor?.codigo,
              permite_fob: vendedor?.permite_fob || "NAO",
              cabotagem: vendedor?.cabotagem || "NAO",
              valida_minimo: vendedor?.valida_minimo || "SIM",
              descsimu_acima_politica: vendedor?.descsimu_acima_politica || "NAO",
              prazosimu_acima_politica: vendedor?.prazosimu_acima_politica || "NAO",
              qtdsimu_rest_politica: !isNaN(+vendedor?.qtdsimu_rest_politica) ? +vendedor?.qtdsimu_rest_politica : 0,
              plataforma_vendedor: vendedor?.plataforma_vendedor || "NEXXUS",
            });
          }
          if (!!policy) {
            const cliente_desconto_extra = !!cliente.DescExtra ? cliente.DescExtra : [];
            const cliente_desconto_geren = !!cliente.DescGeren ? cliente.DescGeren : [];

            this.policy = new Policy(policy, null, cliente_desconto_extra, cliente_desconto_geren);
          }
          this.checkFrete();

          // const { data } = await fetchApi(`/pedidos/cliente-tintometrico/${codigo_cliente}`);

          // if (data.data.length) {
          //   this.data.isClienteTintometrico = true;
          // } else {
          //   this.data.isClienteTintometrico = false;
          // }
        } else {
          throw new PedidoError(`Cliente inválido ou não pertencente a sua região!`);
        }
      } catch (err) {
        if (!!forceReset) this.resetCliente();
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async loadClienteEntrega(forceReset = true) {
    if (!!this.data.cliente_entrega) {
      const codigo_cliente = `${this.data.cliente_entrega}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_entrega ? `${this.data.loja_entrega}`.padStart(2, "0") : "01";
      const filial_faturamento = !!this.data.filial_faturamento
        ? `${this.data.filial_faturamento}`.padStart(2, "0")
        : "01";

      this.isLoading = true;

      try {
        const response = await fetchCliente({ codigo_cliente, loja_cliente, filial_faturamento });
        if (!!response.success) {
          const { cliente } = response.data;

          this.data.cliente_entrega = codigo_cliente;
          this.data.loja_entrega = cliente.Cliente.loja;
          this.data.razao_social_entrega = cliente.Cliente.razao_social;
        } else {
          throw new PedidoError(`Cliente inválido ou não pertencente a sua região!`);
        }
      } catch (err) {
        if (!!forceReset) this.resetClienteEntrega();
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async loadAlvara() {
    if (!!this.data.codigo_alvara) {
      const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";

      this.isLoading = true;

      try {
        const response = await fetchAlvara({ codigo_cliente, loja_cliente, codigo_alvara: this.data.codigo_alvara });
        if (!!response.success) {
          const { codigo_alvara } = response.data;
          this.data.codigo_alvara = codigo_alvara;
        } else {
          throw new PedidoError(`Alvará inválido ou não pertencente a este cliente!`);
        }
      } catch (err) {
        this.data.codigo_alvara = null;
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async loadCupom() {
    if (!!this.data.cupom) {
      const status_pedido = this.data.status_pedido;
      const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";
      const cupom = `${this.data.cupom}`.padStart(6, "0");

      this.isLoading = true;

      try {
        const response = await fetchCupom({ codigo_cliente, loja_cliente, cupom: cupom, status_pedido });
        if (!!response.success) {
          const { cupom, valor_total, valor_utilizado, vigencia } = response.data.Cupom;
          const vnf = Math.max(+this.valor_total_nf, 0);
          const hoje = moment().format("YYYYMMDD");
          const vig = moment(vigencia, "YYYYMMDD");
          this.data.cupom = cupom;
          this.data.cupom_valor = valor_total;
          this.data.cupom_utilizado = valor_utilizado;
          this.data.cupom_valido = true;

          if (hoje > vig._i) {
            throw new PedidoError(`Cupom com data de vigência expirada!`);
          } else if (+valor_total - +valor_utilizado < vnf) {
            throw new PedidoError(
              `Valor total da NF <strong>R$ ` +
              n(+vnf) +
              `</strong> é maior que saldo de <strong>R$ ` +
              n(+valor_total - +valor_utilizado) +
              `</strong> do cupom de brinde !`
            );
          }
        } else {
          throw new PedidoError(`Cupom não encontrado!`);
        }
      } catch (err) {
        this.data.cupom = null;
        this.data.cupom_valido = false;
        this.data.cupom_valor = 0;
        this.data.cupom_utilizado = 0;
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async loadCashback() {
    const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
    const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";

    this.isLoading = true;

    try {
      const response = await fetchCashback({ codigo_cliente, loja_cliente });
      if (!!response.success) {
        const { perc_cashback, saldo_cashback } = response.data.Saldo;

        const vnf = Math.max(+this.valor_total, 2);
        const vcb = vnf * (perc_cashback / 100);

        this.data.percentual_resgate_cashback = perc_cashback;
        this.data.saldo_resgate_cashback = saldo_cashback;
        this.data.valor_resgate_cashback = vcb;

        if (saldo_cashback <= 0) {
          throw new PedidoError(`1 - Cliente sem saldo de cashback!`);
        } else if (saldo_cashback > vcb) {
          this.data.valor_resgate_cashback = vcb;
        } else {
          this.data.valor_resgate_cashback = saldo_cashback;
        }
      } else {
        throw new PedidoError(`2 - Cliente sem saldo de cashback!`);
      }
    } catch (err) {
      this.data.percentual_resgate_cashback = 0;
      this.data.saldo_resgate_cashback = 0;
      this.data.valor_resgate_cashback = 0;
      throw err;
    } finally {
      this.isLoading = false;
    }

    return true;
  }

  @action
  async revogaCashback() {
    const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
    const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";
    const numero_pedido = !!this.numero_pedido ? this.numero_pedido : "      ";
    const filial_faturamento = this.data.filial_faturamento;

    this.isLoading = true;

    try {
      const response = await fetchRevogaCashback({ codigo_cliente, loja_cliente, filial_faturamento, numero_pedido });
      if (!!response.success) {
        this.data.percentual_resgate_cashback = 0;
        this.data.saldo_resgate_cashback = 0;
        this.data.valor_resgate_cashback = 0;
      } else {
        throw new PedidoError(`Cashback não pode ser cancelado!`);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
    return true;
  }

  @action
  async loadPedidoOriginal() {
    if (!!this.data.pedido_originalb2b) {
      const status_pedido = this.data.status_pedido;
      const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";
      const pedido_originalb2b = `${this.data.pedido_originalb2b}`.padStart(6, "0");

      this.isLoading = true;

      try {
        const response = await fetchPedidoOriginal({
          codigo_cliente,
          loja_cliente,
          pedido_originalb2b: pedido_originalb2b,
          status_pedido,
        });
        if (!!response.success) {
          const { pedido_originalb2b, peso_bruto } = response.data.Pedido;
          this.data.pedido_originalb2b = pedido_originalb2b;
          this.data.pedido_originalb2b_peso = peso_bruto;
          this.data.pedido_originalb2b_valido = true;
        } else {
          throw new PedidoError(`Pedido não encontrado ou já está em processo de faturamento!`);
        }
      } catch (err) {
        this.data.pedido_originalb2b = null;
        this.data.pedido_originalb2b_valido = false;
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async loadLoja() {
    if (!!this.data.loja_cliente) {
      this.data.loja_cliente = `${this.data.loja_cliente}`.padStart(2, "0");
    } else {
      this.data.loja_cliente = "01";
    }

    let cliente = false;
    let alvara = false;

    try {
      cliente = await this.loadCliente(false);
      alvara = await this.loadAlvara();
    } catch (err) {
      if (!cliente) {
        this.data.loja_cliente = "01";
        await this.loadCliente();
      }
      if (!alvara) {
        this.data.codigo_alvara = null;
      }
      throw err;
    }

    return true;
  }

  @action
  async loadCondicao() {
    if (!!this.data.codigo_condicao) {
      const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";
      const codigo_condicao = this.data.codigo_condicao.trim().toUpperCase();

      this.isLoading = true;

      try {
        const response = await fetchCondicao({
          codigo_cliente,
          loja_cliente,
          codigo_condicao,
          status_pedido: this.isSimulacao && !this.prazosimu_acima_politica ? "2" : this.data.status_pedido,
          pmp: +this.pmp,
          forma_pagto: this.data.forma_pagto,
        });
        if (!!response.success) {
          const { codigo_condicao, codigo_solicitacao, descricao_condicao, qtd_parcelas = 1, prazo_medio = 1 } = response.data;
          this.data.codigo_condicao = codigo_condicao;
          this.data.codigo_solicitacao = codigo_solicitacao;
          this.data.nome_condicao = descricao_condicao;
          this.data.qtd_parcelas = Math.max(+qtd_parcelas, 1) || 1;
          this.data.prazo_medio = Math.max(+prazo_medio, 1) || 1;
        } else {
          throw new PedidoError(`Condição de pagto inválida ou não permitida para este pedido!`);
        }
      } catch (err) {
        this.data.codigo_condicao = null;
        this.data.nome_condicao = null;
        this.data.qtd_parcelas = 1;
        this.data.prazo_medio = 1;
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async loadTransp() {
    if (!!this.data.codigo_transp) {
      const codigo_transp = `${this.data.codigo_transp}`.padStart(4, "0");

      this.isLoading = true;

      try {
        const response = await fetchTransp({ codigo_vendedor: this.codigo_vendedor, codigo_transp, tipo: "desp" });
        if (!!response.success) {
          const { codigo_transportadora, nome_transportadora } = response.data;
          this.data.codigo_transp = codigo_transportadora;
          this.data.nome_transp = nome_transportadora;
        } else {
          throw new PedidoError(`Não existe transportadora de despacho para esta operação!`);
        }
      } catch (err) {
        this.data.codigo_transp = null;
        this.data.nome_transp = null;
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async loadRedesp() {
    if (!!this.data.redesp_transp) {
      const redesp_transp = `${this.data.redesp_transp}`.padStart(4, "0");

      this.isLoading = true;

      try {
        const response = await fetchTransp({
          codigo_vendedor: this.codigo_vendedor,
          codigo_transp: redesp_transp,
          tipo: "redesp",
        });
        if (!!response.success) {
          const { codigo_transportadora, nome_transportadora } = response.data;
          this.data.redesp_transp = codigo_transportadora;
          this.data.redesp_nome_transp = nome_transportadora;
        } else {
          throw new PedidoError(`Não existe transportadora de redespacho para esta operação!`);
        }
      } catch (err) {
        this.data.redesp_transp = null;
        this.data.redesp_nome_transp = null;
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  async checkStatus() {
    if (!!this.data.status_pedido) {
      const codigo_cliente = `${this.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.data.loja_cliente ? `${this.data.loja_cliente}`.padStart(2, "0") : "01";

      this.isLoading = true;

      try {
        if (this.isSafra && this.data.filial_faturamento !== "01") {
          this.data.status_pedido = "2";
          throw new PedidoError(`Não é possível utilizar o status "Anjo Safra" em uma filial diferente da Matriz.`);
        }

        if ((this.isSimulacao || this.data.isAddSimula) && this.qtdsimu_rest_politica <= 0) {
          this.data.status_pedido = "2";
          throw new PedidoError(`Você não possui mais simulações disponíveis para uso.`);
        }

        if (
          this.hasBonificado &&
          this.data.status_pedido !== "1" &&
          this.data.status_pedido !== "2" &&
          this.data.status_pedido !== "9" &&
          this.data.status_pedido !== "A"
        ) {
          throw new PedidoError(
            `Pedido com itens bonificados devem ter o Status como Orçamento, Pedido, Safra ou Kit.`
          );
        }

        if (
          this.hasBrinde &&
          this.data.status_pedido !== "1" &&
          this.data.status_pedido !== "2" &&
          this.data.status_pedido !== "6"
        ) {
          throw new PedidoError(
            `Pedido com itens de brinde devem ter o Status como Orçamento, Pedido ou Brinde.`
          );
        }

        const response = await fetchStatus({
          codigo_cliente,
          loja_cliente,
          status_pedido: this.data.status_pedido,
          tipo_transp: this.tipoFreteTabela,
        });
        if (!!response.success) {
          const {
            valid = true,
            bonif_amostra_brinde = false,
            pedido_complementar = false,
            message = null,
            prazo_medio_tinta = null,
            prazo_medio_solvente = null,
            tipo_transp_disp = [],
            proporcao = [],
          } = response.data;

          this.bonif_amostra_brinde = bonif_amostra_brinde;
          this.pedido_complementar = pedido_complementar;
          this.tipo_transp_disp = tipo_transp_disp;

          if (!valid) {
            this.data.status_pedido = "2";
            throw new PedidoError(message || `Status inválido para este pedido!`);
          }

          if (this.isSafra) {
            if (tipo_transp_disp.length === 1) {
              this.data.tipo_transp = tipo_transp_disp[0];
            }

            if (!!prazo_medio_solvente && +prazo_medio_solvente > 0) {
              this.max_prazo_medio_solvente = +prazo_medio_solvente;
              this.data.prazo_medio_solvente = +prazo_medio_solvente;
            }

            if (!!prazo_medio_tinta && +prazo_medio_tinta > 0) {
              this.max_prazo_medio_tintacomp = +prazo_medio_tinta;
              this.data.prazo_medio_tintacomp = +prazo_medio_tinta;
            }
            if (this.isSafra && !this.permiteCabotagem) {
              this.data.op_logistica = "L"; // safra fixo como Lotação
            }
          } else {
            if (isFobMatriz(this.data.filial_faturamento, this.permite_fob)) {
              this.data.op_logistica = "L";
            } else if (isFilialFracionado(this.data.filial_faturamento)) {
              this.data.op_logistica = "F";
            } else {
              this.data.op_logistica = null;
            }
            this.data.tipo_transp = null;
          }

          if (this.data.status_pedido.toString() === "6") {
            this.data.tipo_frete = "C";
            this.data.codigo_transp = null;
            this.data.nome_transp = null;

            this.data.redesp_tipo = "C";
            this.data.redesp_transp = null;
            this.data.redesp_nome_transp = null;
          } else {
            this.data.bonif_amostra_brinde = null;
          }

          if (this.data.status_pedido.toString() === "C") {
            this.data.pedido_complementar = true;
          } else {
            this.data.pedido_complementar = false;
          }

          if (this.data.status_pedido.toString() !== "S") {
            this.data.coligacao_simulacao = null;
            this.data.motivo_simulacao = null;
          }

          if (!!proporcao && Array.isArray(proporcao) && proporcao.length > 0) {
            this.proporcao = proporcao;
          } else {
            this.proporcao = [];
          }
        } else {
          throw new PedidoError(`Status inválido para este pedido!`);
        }
      } catch (err) {
        this.data.status_pedido = this.data.isAddSimula ? "S" : "2";
        throw err;
      } finally {
        this.isLoading = false;
      }
    }
    return true;
  }

  @action
  changeTipoFrete(tipo = "C") {
    this.data.tipo_frete = tipo;
    if (tipo === "C") {
      this.data.codigo_transp = null;
      this.data.nome_transp = null;
    }
  }

  @action
  changeRedespTipo(tipo = "C") {
    this.data.redesp_tipo = tipo;
    if (tipo === "C") {
      this.data.redesp_transp = null;
      this.data.redesp_nome_transp = null;
    }
  }

  @action
  checkFrete() {
    //se for B2B não precisa checar os comportamentos de frete
    if (this.isB2B) {
      return;
    }

    //se for status BRINDE não precisa checar os comportamentos de frete
    if (this.data.status_pedido.toString() === "6") {
      this.tipo_frete_enabled = false;
      this.redesp_tipo_enabled = false;
      return;
    }

    if (!!this.permite_fob) {
      this.tipo_frete_enabled = true;
      this.redesp_tipo_enabled = true;
    }

    if (this.data.filial_faturamento === "03" && (this.data.uf === "AM" || this.data.uf === "RR")) {
      if (this.data.tipo_frete === "C") {
        //redespacho
        //campo tipo redespacho = 'f' (fob);
        this.changeRedespTipo("C");
      } else {
        //redespacho
        //campo tipo redespacho = 'c' (cif);
        this.changeRedespTipo("C");
      }
      //campo tipo redespacho desabilitado;
      this.tipo_frete_enabled = false;
      this.redesp_tipo_enabled = false;
    }
    else if (
      ((!this.isRegiaoSudeste && (this.data.filial_faturamento === "03" || this.data.filial_faturamento === "08" || this.data.filial_faturamento === "04"))) &&
      !!this.permite_fob
    ) {
      if (this.data.tipo_frete === "C") {
        //redespacho
        //campo tipo redespacho = 'f' (fob);
        this.changeRedespTipo("F");
      } else {
        //redespacho
        //campo tipo redespacho = 'c' (cif);
        this.changeRedespTipo("C");
      }
      //campo tipo redespacho desabilitado;
      this.redesp_tipo_enabled = false;
    }
    else if (
      ((this.data.filial_faturamento === "04" || this.data.filial_faturamento === "08")) &&
      !!this.permite_fob
    ) {
      this.tipo_frete_enabled = true;
      this.redesp_tipo_enabled = false;
    }
    else if (
      this.data.filial_faturamento === "01" &&
      !!this.permite_fob &&
      this.data.cliente_grande === "SIM" &&
      !this.hasTintaEspecial
    ) {
      //despacho
      //campo tipo frete = 'c' (cif);
      this.changeTipoFrete("C");
      //campo tipo frete desabilitado;
      this.tipo_frete_enabled = false;
      //redespacho
      //campo tipo redespacho = 'c' (cif)
      this.changeRedespTipo("C");
      //campo tipo redespacho desabilitado;
      this.redesp_tipo_enabled = false;
    } else if (
      this.data.filial_faturamento === "01" &&
      !!this.permite_fob &&
      this.data.cliente_grande === "SIM" &&
      !!this.hasTintaEspecial
    ) {
      //despacho
      //campo tipo frete = 'c' (cif);
      this.changeTipoFrete("C");
      //campo tipo frete desabilitado;
      this.tipo_frete_enabled = false;
      //redespacho
      //campo tipo redespacho = 'f' (fob);
      this.changeRedespTipo("F");
      //campo tipo redespacho desabilitado;
      this.redesp_tipo_enabled = false;
    } else if (
      this.data.filial_faturamento === "01" &&
      !!this.permite_fob &&
      this.data.cliente_grande === "NAO" &&
      !this.hasTintaEspecial
    ) {
      //despacho
      //campo tipo frete = 'c' (cif);
      this.changeTipoFrete("C");
      //campo tipo frete desabilitado;
      this.tipo_frete_enabled = false;
      //redespacho
      //campo tipo redespacho = 'f' (fob);
      this.changeRedespTipo("F");
      //campo tipo redespacho desabilitado;
      this.redesp_tipo_enabled = false;
    } else if (this.data.filial_faturamento === "05" && (this.data.uf === "PE" || !!this.permite_fob)) {
      //redespacho
      //campo tipo redespacho = 'c' (cif);
      this.changeRedespTipo("C");
      //campo tipo redespacho desabilitado;
      this.redesp_tipo_enabled = false;
    } else if (!!this.isRegiaoSudeste && (this.data.filial_faturamento === "03" || this.data.filial_faturamento === "08")) {
      this.tipo_frete_enabled = true;
      this.redesp_tipo_enabled = true;
    }
  }

  @action
  resetCondicao() {
    this.data.codigo_condicao = null;
    this.data.nome_condicao = null;
    this.data.qtd_parcelas = 1;
    this.data.prazo_medio = 1;
    this.data.cupom = null;
    this.data.cupom_valido = false;
    this.data.cupom_valor = 0;
    this.data.cupom_utilizado = 0;
    //this.data.percentual_resgate_cashback = 0;
    //this.data.saldo_resgate_cashback = 0;
    //this.data.valor_resgate_cashback = 0;
    this.data.pedido_originalb2b = null;
    this.data_pedido_originalb2b_peso = 0;
    this.data.pedido_originalb2b_valido = false;
    this.fetchDescontoVolume();
  }

  @action
  parse({ Entregas, pedido, cliente, policy, proporcao, vendedor = {}, items }, copy = false, residuo = false) {
    if (!!pedido) {
      if (!copy) {
        this.numero_pedido = pedido.numero_pedido;
        this.codigo_solprazo = pedido.codigo_solprazo;
        this.data.obs_nota = pedido.mensagem_nota || "";
        this.data.ordem_compra = pedido.ordem_compra || "";
        this.data.obs_carregto = pedido.mensagem_carregamento || "";
        this.data.obs_cliente = pedido.observacao_cliente || "";
        this.data.obs_simulacao = pedido.observacao_simulacao || "";
        this.data.codigo_orcamento = !!pedido.codigo_orcamento ? pedido.codigo_orcamento : null;

        if (!!residuo) this.pedido_original = pedido.numero_pedido;
      } else {
        this.pedido_original = pedido.numero_pedido;
      }
      this.data.filial_faturamento = pedido.codigo_filial;
      this.data.status_pedido = pedido.orc_pedido;
      //this.data.mod_logistica =  mod_logistica;

      this.data.codigo_alvara = pedido.codigo_alvara;
      this.data.tipo_transp = pedido.tipo_caminhao;
      this.data.necessita_avaliacao = pedido.avaliacao_comercial || "N";
      this.data.motivo_avaliacao = pedido.motivo_aval_comercial || "";
      this.data.motivo_simulacao = pedido.motivo_simulacao || "";
      this.data.coligacao_simulacao = pedido.coligacao_simulacao || "";
      this.data.tipo_frete = pedido.tipo_frete || "C";
      if (this.data.tipo_frete === "F") {
        this.data.codigo_transp = pedido.codigo_transportadora;
        this.data.nome_transp = pedido.nome_transp;
      }

      this.data.redesp_tipo = pedido.tipo_redespacho || "C";
      if (this.data.redesp_tipo === "F") {
        this.data.redesp_transp = pedido.codigo_redespacho;
        this.data.redesp_nome_transp = pedido.redesp_nome_transp;
      }

      //this.data.codigo_condicao = pedido.condicao_pagamento;
      // this.data.nome_condicao = pedido.descricao_condicao;
      this.data.qtd_parcelas = Math.max(+pedido.qtd_parcelas, 1) || 1;
      this.data.prazo_medio = Math.max(+pedido.prazo_medio, 1) || 1;
      this.data.forma_pagto = pedido.forma_pagamento;
      this.data.cupom = !!pedido.cupom ? pedido.cupom : "";
      this.data.op_logistica = pedido.operacao_logistica || "F";
      this.data.idioma_cliente = pedido.idioma_cliente;

      if (!!pedido.motivo_bonificacao && pedido.motivo_bonificacao !== " ") {
        this.data.bonif_amostra_brinde = pedido.motivo_bonificacao;
        this.bonif_amostra_brinde = true;
      } else {
        this.bonif_amostra_brinde = false;
      }

      if (!!pedido.pedido_original && pedido.pedido_original !== " ") {
        this.data.pedido_original = pedido.pedido_original;
        this.pedido_complementar = true;
      } else {
        this.pedido_complementar = true;
      }

      if (
        !this.data.programado ||
        moment(this.data.programado.split(" ")[0], "YYYY-MM-DD") <
        moment(pedido.data_programado.split(" ")[0], "YYYY-MM-DD")
      ) {
        this.data.programado = pedido.data_programado.split(" ")[0];
      }

      Item.status_pedido = this.isSimulacao && !this.descsimu_acima_politica ? "2" : toJS(this.data.status_pedido);
      Item.unidade_negocio = toJS(this.data.unidade_negocio);

      if (!!pedido?.ticket_tintometrico) {
        this.data.num_kit = pedido.ticket_tintometrico;
        this.data.kit_tintometrico = true;
        this.data.isValidKit = true;
        this.data.opcaoKit = 'S';
      }
    }
    if (!!cliente) {
      this.data.unidade_negocio = cliente.Cliente.unidade_negocio || "REVENDA";
      this.data.codigo_cliente = cliente.Cliente.codigo;
      this.data.loja_cliente = cliente.Cliente.loja;
      this.data.razao_social = cliente.Cliente.razao_social;
      this.data.limite_credito = cliente.Cliente.limite_credito;
      this.data.limite_credito_colig = cliente.Cliente.limite_credito_colig;
      this.data.saldo_total = cliente.boleto.saldo_total;
      this.data.saldo_venc = cliente.boleto.saldo_venc;
      this.data.ultima_compra = cliente.Cliente.ultima_compra;
      this.data.ultimo_faturamento = cliente.Cliente.ultimo_faturamento;
      this.data.ultimo_prazo = cliente.Cliente.ultimo_prazo;
      this.data.cliente_entrega = cliente.Cliente.cliente_entrega;
      this.data.loja_entrega = cliente.Cliente.loja_entrega;
      this.data.razao_social_entrega = cliente.Cliente.razao_social_entrega;
      this.data.tipo_cliente = cliente.Cliente.tipo_cliente;
      this.data.construtora = cliente.Cliente.construtora || "N";
      this.data.uf = cliente.Cliente.uf;
      this.data.cliente_grande = cliente.Cliente.cliente_grande;
      this.data.cod_canal = cliente.Cliente.cod_canal;
      this.parcela_minima = this.data.cod_canal === "10" ? 50 : !!this.isB2B ? 700 : 500;
      this.data.icms_operacao = +cliente.ICMS.icms_operacao;
      this.data.icms_interno = +cliente.ICMS.icms_interno;
      this.data.prazo_medio_solvente = Math.min(+pedido.prazo_medio_solvente, +cliente.Cliente.prazo_medio_solvente);
      this.data.prazo_medio_tintacomp = Math.min(+pedido.prazo_medio_tintacomp, +cliente.Cliente.prazo_medio_tintacomp);
      this.data.prazomedio_cliente = Math.max(+cliente.Cliente.prazomedio_cliente, 0);
      this.data.calc_acresc_condpag = cliente.Cliente.calc_acresc_condpag || "NAO";
      this.data.calc_descre_condpag = cliente.Cliente.calc_descre_condpag || "NAO";
      this.data.frmto_calc_condpag = cliente.Cliente.frmto_calc_condpag || "REFERENCIA";
      this.max_prazo_medio_solvente = +cliente.Cliente.prazo_medio_solvente;
      this.max_prazo_medio_tintacomp = +cliente.Cliente.prazo_medio_tintacomp;
      this.data.desconto_volume_escalonado = "DescVolume" in cliente ? cliente.DescVolume : null;
      this.statusCad = cliente.Cliente.status_cad || null;
      this.selectedEntrega = {
        codigo_entrega: cliente.Cliente.codigo_entrega,
      }
      this.peso_minimo_parametro = parseFloat(cliente.Parametro.peso_minimo.replace(/,/g, '.'));

      if (copy) {
        this.data.valor_resgate_cashback = 0;
        this.data.percentual_resgate_cashback = 0;
        this.data.saldo_resgate_cashback = 0;
      } else {
        this.data.valor_resgate_cashback = +pedido.valor_cashback;
        this.data.percentual_resgate_cashback = +pedido.percentual_cashback;
        this.data.saldo_resgate_cashback = +pedido.saldo_cashback;
      }

      if (!!cliente.Cliente.cd_ultima_cond_pgto)
        this.cliente_cod_ultima_cond_pgto = cliente.Cliente.cd_ultima_cond_pgto;
      if (!!cliente.Cliente.dt_ultima_cond_pgto)
        this.cliente_dt_ultima_cond_pgto = cliente.Cliente.dt_ultima_cond_pgto.split(" ")[0];

      this.condicao_esp =
        "CondicaoEsp" in cliente && !!cliente.CondicaoEsp && !!cliente.CondicaoEsp.codigo_condicao
          ? { ...cliente.CondicaoEsp }
          : null;
      this.condicao_tc =
        "CondicaoTc" in cliente && !!cliente.CondicaoTc && !!cliente.CondicaoTc.codigo_condicao
          ? { ...cliente.CondicaoTc }
          : null;
      this.condicao_sol =
        "CondicaoSol" in cliente && !!cliente.CondicaoSol && !!cliente.CondicaoSol.codigo_condicao
          ? { ...cliente.CondicaoSol }
          : null;

      if (!!policy) {
        const cliente_desconto_extra = !!cliente.DescExtra ? cliente.DescExtra : [];
        const cliente_desconto_geren = !!cliente.DescGeren ? cliente.DescGeren : [];

        this.policy = new Policy(
          policy,
          !!pedido.desconto_politica ? pedido.desconto_politica : "xxxxxxxxxx",
          cliente_desconto_extra,
          cliente_desconto_geren
        );
      }

      if (!!cliente.Cliente.VerbaBon && this.isPedidoNormal) {
        this.data.verba_codigo = cliente.Cliente.VerbaBon.codigo;
        this.data.verba_ccusto = cliente.Cliente.VerbaBon.centro_custo;
        this.data.verba_descc = cliente.Cliente.VerbaBon.desc_custo;
        this.data.verba_valor = +cliente.Cliente.VerbaBon.verba_valor;
        this.data.verba_utilizado = +cliente.Cliente.VerbaBon.verba_utilizado;
      } else {
        this.data.verba_codigo = null;
        this.data.verba_ccusto = null;
        this.data.verba_descc = null;
        this.data.verba_valor = 0;
        this.data.verba_utilizado = 0;
      }
    }
    if (!!Entregas) {
      this.entregas = Entregas;
    }
    if (!!proporcao) {
      this.proporcao = proporcao;
    }
    if (!!vendedor.codigo) {
      this.updateVendedorData({
        codigo_vendedor: vendedor?.codigo,
        permite_fob: vendedor?.permite_fob || "NAO",
        cabotagem: vendedor?.cabotagem || "NAO",
        valida_minimo: vendedor?.valida_minimo || "SIM",
        descsimu_acima_politica: vendedor?.descsimu_acima_politica || "NAO",
        prazosimu_acima_politica: vendedor?.prazosimu_acima_politica || "NAO",
        qtdsimu_rest_politica: !isNaN(+vendedor?.qtdsimu_rest_politica) ? +vendedor?.qtdsimu_rest_politica : 0,
        plataforma_vendedor: vendedor?.plataforma_vendedor || "NEXXUS",
      });
    }
    if (!!items && items.length > 0) {
      items.forEach(itemData => {
        const _item = new Item(
          { ...itemData, Cliente: { ...cliente.Cliente } },
          !!this.policy && this.policy.values,
          false
        );

        _item.applyTaxa(this.condicaoTaxa, false);

        // itens inválidos (que já estão com descontos "expirados") travar os descontos até revisão manual do item...
        _item.desc_locked = !_item.isValid;

        this.items.push(_item);
      });
      //adicionado para atualizar o calculo de desconto das campanhas de volume, eh necessario para forcar sempre o calculo assim, quando um acordo de desconto por volume terminar, caso o pedido estiver com os percentuais de desconto, antes de transformar em peidodo o nexxus recalcula e retira os descontos desse pedido.
      this.fetchDescontoVolume();
    }

    if (pedido.orc_pedido === "T") {
      const validCodes = ["000013", "000014"];

      const isValidCode = (code) => validCodes.includes(code);

      if (isValidCode(cliente.Cliente.cod_perfil_aut)) {

        if (!cliente.Cliente.cod_perfil_aut_forc) {
          this.forcePolicyMaster();
        } else if (isValidCode(cliente.Cliente.cod_perfil_aut_forc)) {
          this.forcePolicyMaster();
        }
      }

      if (isValidCode(cliente.Cliente.cod_perfil_imob)) {
        if (!cliente.Cliente.cod_perfil_imob_forc) {
          this.forcePolicyMaster();
        } else if (isValidCode(cliente.Cliente.cod_perfil_imob_forc)) {
          this.forcePolicyMaster();
        }
      }
    }

    this.checkFrete();
  }

  @action
  resetCliente() {
    this.data.codigo_cliente = null;
    this.data.loja_cliente = null;
    this.data.razao_social = null;
    this.data.limite_credito = 0;
    this.data.limite_credito_colig = 0;
    this.data.codigo_alvara = null;
    this.data.ultimo_faturamento = 0;
    this.data.ultima_compra = 0;
    this.data.ultimo_prazo = 0;
    this.data.op_logistica = null;
    this.data.prazo_medio_solvente = 0;
    this.data.prazo_medio_tintacomp = 0;
    this.data.tipo_cliente = null;
    this.data.construtora = "N";
    this.data.necessita_avaliacao = "N";
    this.data.motivo_avaliacao = null;
    this.data.motivo_simulacao = null;
    this.data.coligacao_simulacao = null;
    this.data.uf = null;
    this.data.cliente_grande = null;
    this.data.cod_canal = null;
    this.data.prazomedio_cliente = 0;
    this.data.calc_acresc_condpag = "NAO";
    this.data.calc_descre_condpag = "NAO";
    this.data.frmto_calc_condpag = "REFERENCIA";
    this.data.icms_operacao = 0;
    this.data.icms_interno = 0;
    this.data.status_pedido = this.data.isAddSimula ? "S" : !!this.isB2B ? "1" : "2";
    this.bonif_amostra_brinde = false;
    this.pedido_complementar = false;
    this.pedido_original = null;
    this.policy = null;
    this.cliente_cod_ultima_cond_pgto = null;
    this.cliente_dt_ultima_cond_pgto = null;
    this.condicao_esp = null;
    this.condicao_tc = null;
    this.condicao_sol = null;
    this.resetCondicao();

    //tratamento para quando der reset no pedido, persistir a regra de operação logistica...
    if (isFobMatriz(this.data.filial_faturamento, this.permite_fob)) {
      this.data.op_logistica = "L";
    } else if (isFilialFracionado(this.data.filial_faturamento)) {
      this.data.op_logistica = "F";
    }
  }

  @action
  resetClientEntrega() {
    this.data.cliente_entrega = null;
    this.data.loja_entrega = null;
    this.data.razao_social_entrega = null;
  }

  @action
  selectIdiomaCliente(ling) {
    this.data.idioma_cliente = ling;
  }

  @action
  forcePolicyMaster = async () => {
    try {
      this.isLoading = true;
      const { data } = await fetchApi('/pedidos/find-policy-forcado-orcamento-tintometrico');
      this.data.obs_carregto = "Solicitação de orçamento tintométrico, ignorar.";
      this.data.obs_nota = "Solicitação de orçamento tintométrico, ignorar.";
      this.policy.update(data);
      this.data.orcamentoTintometrico = true;

      if (this.hasItems) {
        this.items.forEach(item => {
          this.removeItem(item);
        })
      }
    } catch (error) {

    } finally {
      this.isLoading = false;
    }
  }

  exportData() {
    const data = toJS(this.data);
    const anppol = !!this.policy ? toJS(this.policy.anppol) : null;
    const items = this.items.map(item => item.asJSON);
    const removed = this.removedItems.map(ritem => ritem.asJSON);

    return {
      Pedido: {
        numero_pedido: toJS(this.numero_pedido),
        pedido_original: toJS(this.pedido_original),
        codigo_vendedor: toJS(this.codigo_vendedor),
        codigo_operador: toJS(this.codigo_operador),
        venda_interna: toJS(this.venda_interna),
        condicao_taxa: toJS(this.condicaoTaxa),
        ...data,
        anppol,
        entrega: toJS(this.selectedEntrega),
      },
      PedidoItem: [...removed, ...items],
      CondicaoEsp: toJS(this.condicao_esp),
      CondicaoTc: toJS(this.condicao_tc),
      CondicaoSol: toJS(this.condicao_sol),
    };
  }

  @computed
  get asJSON() {
    const data = toJS(this.data);
    const policy = !!this.policy ? this.policy.asJSON : null;
    const items = this.items.map(item => item.asJSON);
    const removed = this.removedItems.map(ritem => ritem.asJSON);
    const totais = {
      total_items: this.items.length,
      total_auto: this.total_auto,
      desconto_auto_total: this.desconto_auto_total,
      total_imob: this.total_imob,
      desconto_imob_total: this.desconto_imob_total,
      peso_bruto_total: this.peso_bruto_total,
      desconto_total: this.desconto_total,
      valor_total: this.valor_total,
      valor_ipi: this.valor_ipi,
      base_icms: this.base_icms,
      valor_icms: this.valor_icms,
      base_st: this.base_st,
      valor_icms_st: this.valor_icms_st,
      valor_suframa: this.valor_suframa,
      valor_total_nf: this.valor_total_nf,
      pmp: this.pmp,
    };

    return {
      data: {
        numero_pedido: toJS(this.numero_pedido),
        pedido_original: toJS(this.pedido_original),
        codigo_vendedor: toJS(this.codigo_vendedor),
        codigo_operador: toJS(this.codigo_operador),
        venda_interna: toJS(this.venda_interna),
        cliente_cod_ultima_cond_pgto: toJS(this.cliente_cod_ultima_cond_pgto),
        cliente_dt_ultima_cond_pgto: toJS(this.cliente_dt_ultima_cond_pgto),
        ...data,
        totais,
      },
      items,
      removed,
      policy,
    };
  }

  //Bloqueia campo caso não seja bonificação ou amostra
  @computed
  get isLiberadoObsCarregamento() {
    return this.isBonificacao || this.isAmostra;
  }
}

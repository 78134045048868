import React from "react";
import { Alert, Col, Row } from "antd";

const Alerts = ({ alerts, ...alertProps }) => {
  return (
    <Row type="flex" gutter={15} >
      {alerts.map((props) => (
        <Col span={24 / alerts.length}>
          <Alert
            style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15 }}
            showIcon
            {...alertProps}
            {...props}
          />
        </Col>
      ))}
    </Row>
  );
};

export default Alerts;

import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import { PieChart, Pie, Cell } from "recharts";
import _capitalize from "lodash/capitalize";
import styled from "styled-components";
import _isEqual from "lodash/isEqual";

import { n } from "@util";
import { fetchMix } from "actions/dashboard";
import moment from "moment";
import { MESES } from "@util";

const COLORS = ["#02aed9", "#446fb8", "#34e5d5"];
const RADIAN = Math.PI / 180;

const DashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; 
`;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, volume, symbol, index, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) + 27;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <g>
      <text x={x + 10} y={y - 25} fontWeight={900} fontSize={48} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" fill="#666">
        {`${(percent * 100).toFixed(0)}% ${(_capitalize(name))}`}
      </text>
      <text
        x={x + 15}
        y={y - 25}
        dy={38}
        fontSize={30}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fill="#969696">
        {`${(n(volume))} ${(symbol)}`}
      </text>
    </g>
  );
};

const AuxChart = ({ label, data = [], isB2B, children, ...props }) => {
  const width = window.innerWidth * 0.6;

  return (
    <PieChart width={window.innerWidth} height={window.innerHeight * 0.9} style={{ margin: "0 auto" }}> {/* Centraliza o MixChart */}
      <Pie
        cx={"50%"}
        cy={"50%"}
        innerRadius={width * 0.15}
        outerRadius={width * 0.25}
        data={data}
        dataKey="percentual_mix"
        nameKey="desc_classe"
        label={renderCustomizedLabel}
        {...props}>
        {data.map((entry, index) => (
          <Cell key={entry.desc_classe} volume={entry.realizado} symbol={isB2B ? "Kg" : "Litros"} fill={COLORS[index % COLORS.length]} />
        ))}
        {children}
      </Pie>
    </PieChart>
  )
};

export default class MixPanelOTImob extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    (async () => {
      await this.fetchData();
      const interval = setInterval(this.fetchData, 300000);
      this.setState({ interval });
    })()
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    this.setState({ interval: false });
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.fetchData();
      //const interval = setInterval(this.fetchData, 50000);
    }
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const response = await fetchMix(!!this.props.isTipoVendaInterna ? this.props.relacionadoData.codigo : null);
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, data } = this.state;
    const currentMonth = MESES[moment().format('MM')];

    return (
      <Spin spinning={isLoading}>
        <div style={{ minHeight: 180 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: "center",
            fontSize: 19,
          }}>
            <h1>Televendas - Posição Mix Imobiliária {currentMonth}</h1>
          </div>
          {!!data && (
            <DashboardBody>
              {!!data.items.mix && data.items.mix.length > 0 ? (
                <Fragment>

                  {!!data.items.auto && data.items.auto.length > 0 && (
                    <AuxChart label="Imobiliária" data={data.items.imob} isB2B={this.props.isB2B} />
                  )}

                </Fragment>
              ) : (
                <p>
                  <strong>Dados insuficientes para montar o seu gráfico de MIX.</strong>
                </p>
              )}
            </DashboardBody>
          )}
        </div>
      </Spin>
    );
  }
}
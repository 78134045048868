import React, { useEffect, useState } from "react";
import "./ranking.less";
import { fetchApi } from "actions";
import { Spin } from "antd";
import { MESES } from "@util";
import moment from "moment";

const Ranking = () => {
  const [data, setData] = useState([]);
  const [fotos, setFotos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: response } = await fetchApi('/dashboard/dashrank');

        if (!!response.success) {
          let fotos = [];
          for (let i = 0; i < response.data.length; i++) {
            const el = response.data[i];

            const url = response.downloaduri + '/' + el.operador_televendas + '.jpg';

            fotos.push(url);
            setFotos(fotos)
          }

          setData(response.data);
        }
      } catch (error) {
        console.log('error -> ', error);
      } finally {
        setLoading(false);
      }
    })()
  }, [])

  const RenderPosition = ({ position }) => (
    <div
      key={position}
      className={`podium-step step-${position}`}
    >
      <img src={fotos[position - 1]} alt={`Avatar`} />
      <span className="position">{position}º</span>
      {data && data.length > 0 && <span className="name">{data[position - 1]?.nome_operador}</span>}
    </div>
  )

  const priorMonth = MESES[moment().subtract(1, 'month').format('MM')];

  return (
    <Spin spinning={loading}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: "center",
        fontSize: 19,
        height: 0
      }}>
        <h1 style={{ marginBottom: 0 }}>Destaques do mês de {priorMonth}</h1>
      </div>
      <div className="podium-wrapper">
        <div className="podium-container">
          {<RenderPosition position={2} />}
          {<RenderPosition position={1} />}
          {<RenderPosition position={3} />}
        </div>
      </div>
    </Spin>
  );
};

export default Ranking;
